import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withLocalization from '../../utils/withLocalization';
import './CustomTable.scss';

const CustomTable = (props) => {
  const { tableHeadings, children, className, t } = props;

  return (
    <div className={classNames('table-responsive custom-table', className)}>
      <table className="table">
        <thead>
          <tr>
            {tableHeadings.map((heading) => (
              <th key={heading}>{t(heading)}</th>
            ))}
          </tr>
        </thead>
        <If condition={children}>
          <tbody>{children}</tbody>
        </If>
      </table>
    </div>
  );
};

CustomTable.propTypes = {
  tableHeadings: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};
CustomTable.defaultProps = {
  children: null,
  className: null,
};

export default withLocalization(CustomTable);
