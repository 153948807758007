import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import BackToTop from '../../components/BackToTop';
import Banner from '../../components/Banner';
import './MainLayout.scss';

const MainLayout = ({ children, image }) => (
  <Fragment>
    <Header />
    <Navigation />
    <main className="main-layout">
      <Choose>
        <When condition={image}>
          <Banner image={image} />
          <div className="container my-2 my-md-4 main-layout__container">{children}</div>
        </When>
        <Otherwise>{children}</Otherwise>
      </Choose>
    </main>
    <Footer />
    <BackToTop />
  </Fragment>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
};

MainLayout.defaultProps = {
  image: '',
};

export default MainLayout;
