import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import './Logo.scss';

const Logo = ({ className, to, onClick }) => {
  const logo = <img className="logo__image" src="/img/logo.svg" alt="silmarion" />;

  return (
    <div className={classNames('logo', className)}>
      <Choose>
        <When condition={to}>
          <Link className="logo__link" onClick={onClick} to={to}>
            {logo}
          </Link>
        </When>
        <When condition={onClick}>
          <span className="logo__content" onClick={onClick} role="presentation">
            {logo}
          </span>
        </When>
        <Otherwise>{logo}</Otherwise>
      </Choose>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Logo.defaultProps = {
  className: null,
  to: null,
  onClick: null,
};

export default Logo;
