import gql from 'graphql-tag';

const GET_AFFILIATE_ID = gql`
  query profile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        affiliateId
      }
    }
  }
`;

export default GET_AFFILIATE_ID;
