import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import SlickSlider from '../SlickSlider';
import withLocalization from '../../utils/withLocalization';
import PropTypes from '../../constants/prop-types';
import Timer from '../Timer';
import EventItem from '../EventItem';
import { STATUSES } from '../../constants/statuses';
import CustomTable from '../CustomTable';

const Lottery = (props) => {
  const {
    title,
    image,
    t,
    prizePool,
    id,
    slider,
    prizes,
    icon,
    startDate,
    endDate,
    status,
    className,
    titleClassName,
    actionClassName,
    prizePoolClassName,
    subtitleClassName,
  } = props;

  const delay = moment
    .duration(moment.utc(startDate && moment.utc(startDate).isAfter() ? startDate : endDate).diff())
    .asMilliseconds();
  const tableHeadings = ['t.count', 't.prize'];

  return (
    <EventItem
      title={title}
      icon={icon}
      className={className}
      titleClassName={titleClassName}
      image={image}
      prizePool={prizePool}
      link={`/lotteries/${id}`}
      actionClassName={actionClassName}
      prizePoolClassName={prizePoolClassName}
    >
      <Choose>
        <When condition={slider}>
          <div className="event-item__slider">
            <div className="event-item__paragraph">{t('t.gifts')}:</div>
            <SlickSlider autoplay autoplaySpeed={3600} slidesToShow={3} speed={800}>
              <For of={prizes} index="index" each="prize">
                <div key={index} className="event-item__slider-content">
                  <img className="img-fluid" src={prize.image} alt={prize.amount} />
                  <div className="event-item__slider-label">{prize.title}</div>
                </div>
              </For>
            </SlickSlider>
          </div>
        </When>
        <Otherwise>
          <div className={classNames('event-item__subtitle', subtitleClassName)}>{t('t.lottery.main.present')}:</div>
          <CustomTable tableHeadings={tableHeadings}>
            {prizes.map((prize, index) => (
              <tr key={index}>
                <td className="event-item__prize-count">{prize.amount}x</td>
                <td>{prize.title}</td>
              </tr>
            ))}
          </CustomTable>
          <Timer
            className="event-item__timer"
            label={status === STATUSES.PENDING ? 't.timer.startTime' : 't.timer.endTime'}
            delay={delay}
          />
        </Otherwise>
      </Choose>
    </EventItem>
  );
};

Lottery.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  prizePool: PropTypes.money.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  prizes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      image: PropTypes.string,
    })
  ),
  slider: PropTypes.bool,
  status: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  actionClassName: PropTypes.string,
  prizePoolClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
};
Lottery.defaultProps = {
  prizes: [],
  slider: false,
  icon: null,
  className: null,
  titleClassName: null,
  actionClassName: null,
  prizePoolClassName: null,
  subtitleClassName: null,
};

export default withLocalization(Lottery);
