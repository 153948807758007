import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Modal, ModalHeader, ModalBody } from '../Modal';
import Link from '../Link';
import history from '../../router/history';
import { content } from './constants';
import getDeviceType from '../../utils/getDeviceType';
import { SliderArrowPrev, SliderArrowNext } from '../SliderArrows';
import SlickSlider from '../SlickSlider';
import Button from '../Button';
import './PostRegistrationModal.scss';

class PostRegistrationModal extends PureComponent {
  static propTypes = {
    bonusCampaigns: PropTypes.shape({
      bonusCampaigns: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            campaignUUID: PropTypes.string,
            parentCampaignUUID: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.string,
          })
        ),
      }),
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    optInMutation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  get registrationBonusCampaign() {
    const {
      bonusCampaigns: { bonusCampaigns },
    } = this.props;

    return get(bonusCampaigns, 'data[0]');
  }

  handleOptIn = async () => {
    const {
      optInMutation,
      uuid: playerUUID,
      handleCloseModal,
      match: {
        params: { lang },
      },
    } = this.props;
    const {
      registrationBonusCampaign: { campaignUUID, parentCampaignUUID },
    } = this;

    await optInMutation({
      variables: {
        campaignUUID,
        parentCampaignUUID,
        playerUUID,
        deviceType: getDeviceType(),
      },
    });
    handleCloseModal();
    history.push({ pathname: `/${lang}/player/cashbox`, state: { modal: true } });
  };

  render() {
    const {
      isOpen,
      handleCloseModal,
      t,
      match: {
        params: { lang },
      },
    } = this.props;
    const { registrationBonusCampaign } = this;

    if (!registrationBonusCampaign) {
      return null;
    }

    const { image, title } = registrationBonusCampaign;

    return (
      <Modal isOpen={isOpen} toggle={handleCloseModal} backdrop="static">
        <ModalHeader title={t('t.postreg_modal.title')} toggle={handleCloseModal} />
        <ModalBody className="post-registration">
          <SlickSlider
            arrows
            prevArrow={<SliderArrowPrev />}
            nextArrow={<SliderArrowNext />}
            dots
            dotsClass="post-registration__dots"
            fade
            speed={700}
          >
            <div>
              <div className="row no-gutters">
                <div className="col-10 col-md-5 post-registration__info">
                  <h3 className="post-registration__title">{t('t.postreg_modal.first_slide.title')}</h3>
                  <p className="post-registration__description">{t('t.postreg_modal.first_slide.description')}</p>
                  <div className="post-registration__image-wrapper">
                    <img className="img-fluid" src={image} alt={title} />
                  </div>
                  <Button type="button" onClick={this.handleOptIn} className="post-registration__button">
                    {t('t.postreg_modal.first_slide.action')}
                  </Button>
                  <Link
                    className="post-registration__link"
                    onClick={handleCloseModal}
                    to={{ pathname: `/${lang}/player/bonuses`, state: { modal: true } }}
                  >
                    {t('t.postreg_modal.first_slide.link')}
                  </Link>
                </div>
                <div className="d-none d-md-block col-md-6">
                  <img src="/img/post-registration-modal/first-slide-bg.png" alt={title} />
                </div>
              </div>
            </div>
            {content.map(({ slideTitle, description, slideImage, buttonLabel, background }) => (
              <div key={slideImage}>
                <div className="row no-gutters">
                  <div className="col-10 col-md-6 post-registration__info pl-md-4 pr-md-3">
                    <h3 className="post-registration__title">{t(slideTitle)}</h3>
                    <p className="post-registration__description">{t(description)}</p>
                    <div className="post-registration__image-wrapper">
                      <img className="img-fluid" src={slideImage} alt={t(slideTitle)} />
                    </div>
                    <Button
                      tag={Link}
                      className="post-registration__button"
                      onClick={handleCloseModal}
                      to={{ pathname: `/${lang}/player/cashbox`, state: { modal: true } }}
                    >
                      {t(buttonLabel)}
                    </Button>
                  </div>
                  <div className="d-none d-md-block col">
                    <img src={background} alt={t(slideTitle)} />
                  </div>
                </div>
              </div>
            ))}
          </SlickSlider>
        </ModalBody>
      </Modal>
    );
  }
}

export default PostRegistrationModal;
