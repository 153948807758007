import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormGroup from '../FormGroup';

const RadioButtonGroup = ({
  options,
  input,
  meta: { touched, error },
  label,
  labelClassName,
  hideErrorMessage,
  isRequired,
  horizontal,
  className,
  hugeIcons,
  inputWrapperClassName,
}) => (
  <FormGroup
    touched={touched}
    error={error}
    label={label}
    labelClassName={labelClassName}
    hideErrorMessage={hideErrorMessage}
    required={isRequired}
    className={className}
    horizontal={horizontal}
    inputWrapperClassName={inputWrapperClassName}
  >
    {options.map((o) => (
      <span key={o.label} className="form-check form-check-inline">
        <label className="radio">
          <input type="radio" {...input} value={o.value} checked={o.value === input.value} />
          <span className={classNames('radio-icon', { 'radio-icon--big': hugeIcons })} />
          <span className="radio-label">{o.label}</span>
        </label>
      </span>
    ))}
  </FormGroup>
);

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  horizontal: PropTypes.bool,
  hideErrorMessage: PropTypes.bool,
  className: PropTypes.string,
  hugeIcons: PropTypes.bool,
  inputWrapperClassName: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  label: null,
  labelClassName: null,
  isRequired: false,
  horizontal: false,
  hideErrorMessage: false,
  className: null,
  hugeIcons: false,
  inputWrapperClassName: null,
};

export default RadioButtonGroup;
