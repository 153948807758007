import React, { PureComponent } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, NavLink } from '../../../components';
import PropTypes from '../../../constants/prop-types';
import ActiveBonusProgressBar from '../../ActiveBonusProgressBar';
import withLocalization from '../../../utils/withLocalization';
import { navigation } from '../../../constants/menu';
import './MobileMenu.scss';

class MobileMenu extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    bonus: PropTypes.shape({
      bonusType: PropTypes.string,
      progress: PropTypes.number,
      leftToWage: PropTypes.money,
      amountToWage: PropTypes.money,
    }),
    handleCloseMenu: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string,
      })
    ),
  };
  static contextTypes = {
    auth: PropTypes.object,
  };
  static defaultProps = {
    bonus: {},
    categories: [],
  };

  render() {
    const { bonus, t, handleCloseMenu, categories } = this.props;
    const {
      auth: { logged },
    } = this.context;

    return (
      <Nav className="d-md-none mobile-menu" navbar>
        <If condition={bonus}>
          <NavItem className="d-sm-none mobile-menu__bonus">
            <If condition={bonus.image}>
              <img className="mobile-menu__bonus-image" src={bonus.image} alt="bonus" />
            </If>
            <ActiveBonusProgressBar id="bonus" bonus={bonus} />
          </NavItem>
        </If>
        <NavItem className="mobile-menu__divider" />
        {navigation.map((item) => (
          <NavItem key={item.label} className="mobile-menu__item">
            <NavLink to={item.url} className="link" onClick={handleCloseMenu}>
              {t(item.label)}
            </NavLink>
          </NavItem>
        ))}
        <NavItem className="mobile-menu__divider" />
        {categories.map((category) => (
          <NavItem key={category.key} className="mobile-menu__item">
            <NavLink to={category.url} className="link" onClick={handleCloseMenu}>
              {t(category.label)}
            </NavLink>
          </NavItem>
        ))}
        <If condition={logged}>
          <NavItem className="mobile-menu__divider" />
          <NavItem className="mobile-menu__item">
            <RouterLink to="/logout" className="mobile-menu__logout">
              <Icon icon="logout" className="mobile-menu__icon" />
              {t('t.logout')}
            </RouterLink>
          </NavItem>
        </If>
      </Nav>
    );
  }
}

export default withLocalization(MobileMenu);
