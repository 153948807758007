import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import IndexRoute from '../components/IndexRoute';
import { getBrandId, getDomain } from '../../../config';
import withModals from '../../../utils/withModals';
import PostRegistrationModal from '../../../components/PostRegistrationModal';
import ErrorModal from '../../../components/ErrorModal';

const mapStateToProps = ({ auth, i18nState: { lang: locale } }) => ({ auth, locale });

const query = gql`
  query options($brandId: String!) {
    options {
      signUp(brandId: $brandId) {
        post {
          currency {
            base
            list
          }
        }
      }
    }
  }
`;

const domainDataQuery = gql`
  query domainData($domain: String!, $locale: String!) {
    domainData(domain: $domain, locale: $locale) {
      data {
        title
        metaDescription
        redirect
      }
    }
  }
`;

export default compose(
  connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
  connect(mapStateToProps),
  graphql(domainDataQuery, {
    options: ({
      match: {
        params: { lang: locale },
      },
    }) => ({
      variables: {
        domain: getDomain(),
        locale,
      },
    }),
    name: 'domainData',
  }),
  graphql(query, {
    options: () => ({
      variables: {
        brandId: getBrandId(),
      },
    }),
  }),
  withModals({
    postRegistrationModal: PostRegistrationModal,
    errorModal: ErrorModal,
  })
)(IndexRoute);
