import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames';
import PropTypes from '../../../../constants/prop-types';
import { createValidator } from '../../../../utils/validator';
import normilizeTrim from '../../../../utils/normilizeTrim';
import { Input, CheckBox, Select } from '../../../../components/Form';
import { Button, SocialIcons, Link } from '../../../../components';
import LoadingButton from '../../../../components/LoadingButton';
import retryFunction from '../../../../utils/retryFunction';
import { currencySettings } from '../../../../constants/currency';
import { cms } from '../../../../utils';
import './SignUpForm.scss';

const attributeLabels = {
  email: 'Email',
  password: 'Password',
};

const validator = (
  values,
  {
    data: {
      options: {
        signUp: {
          post: { passwordPattern },
        },
      },
    },
  }
) =>
  createValidator(
    {
      email: 'required|email',
      password: ['required', `regex:${passwordPattern}`],
      terms: 'required',
      currency: 'required',
    },
    attributeLabels
  )(values);

class SignUpForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    error: PropTypes.string,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    createPlayer: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    cmsSignIn: PropTypes.func.isRequired,
    data: PropTypes.shape({
      options: PropTypes.shape({
        signUp: PropTypes.shape({
          post: PropTypes.shape({
            currency: PropTypes.shape({
              list: PropTypes.arrayOf(PropTypes.string),
            }),
            passwordPattern: PropTypes.string,
          }),
        }),
      }),
    }).isRequired,
    returnUrl: PropTypes.string,
    className: PropTypes.string,
  };
  static defaultProps = {
    handleSubmit: null,
    submitting: false,
    error: null,
    returnUrl: '',
    className: null,
  };
  static contextTypes = {
    previousLocation: PropTypes.object,
    mainModals: PropTypes.shape({
      postRegistrationModal: PropTypes.modalType,
    }),
  };

  handleSubmit = async (data) => {
    const {
      history,
      signIn,
      cmsSignIn,
      createPlayer,
      data: { variables },
      match: {
        params: { lang },
      },
      affiliate: { affiliateId, btag },
      clearAffiliate,
      returnUrl,
    } = this.props;

    const {
      previousLocation: { pathname: previousLocation },
      mainModals: { postRegistrationModal },
    } = this.context;

    const result = await createPlayer({
      variables: {
        ...variables,
        ...data,
        languageCode: lang,
        affiliateId,
        btag,
        identifier: '0',
      },
    });

    const error = get(result, 'data.player.create.error');

    if (!error) {
      const signInData = { password: data.password, login: data.email };
      let signInResult = null;

      if (affiliateId && btag) {
        signInResult = await retryFunction(() => cmsSignIn({ ...signInData, affiliateId }));
      } else {
        signInResult = await retryFunction(() => signIn(signInData));
      }

      const playerUUID = get(result, 'data.player.create.data.playerUUID');

      if (signInResult && !signInResult.error && playerUUID) {
        cms.sendSignInEvent(playerUUID);
      }

      clearAffiliate();
      postRegistrationModal.show();

      history.replace(returnUrl || previousLocation);
    } else {
      const fieldsErrors = mapValues(error.fields_errors, 'error');

      throw new SubmissionError({ _error: !fieldsErrors ? error.error : '', ...fieldsErrors });
    }
  };

  render() {
    const {
      submitting,
      error,
      returnUrl,
      handleSubmit,
      t,
      data: { loading, options },
      className,
    } = this.props;

    if (loading) {
      return null;
    }

    const currencyList = get(options, 'signUp.post.currency.list', []);
    const currencyOptions = orderBy(
      currencyList.map((currency) => ({
        label: currency,
        value: currency,
        order: currencySettings[currency] ? currencySettings[currency].order : 0,
      })),
      ['order'],
      ['asc', 'desc']
    );

    return (
      <form className={classNames('sign-up-form', className)} onSubmit={handleSubmit(this.handleSubmit)}>
        <If condition={error}>
          <div className="alert alert-warning sign-up-form__alert">{error}</div>
        </If>
        <Field name="email" type="email" label={t('t.email')} component={Input} />
        <Field name="password" type="password" label={t('t.password')} normalize={normilizeTrim} component={Input} />
        <Field
          className="sign-up-form__currency"
          name="currency"
          type="text"
          options={currencyOptions}
          component={Select}
          placeholder={t('t.currency_placeholder')}
        />
        <Field name="terms" type="checkbox" component={CheckBox} label={t('t.accept.terms')} />
        <Button
          type="submit"
          tag={LoadingButton}
          className="sign-up-form__button"
          disabled={submitting}
          loading={submitting}
        >
          {t('t.register.btn')}
        </Button>
        <div className="sign-up-form__already-reg">
          {t('t.already.registered')}
          <Link
            className="sign-up-form__already-regLink"
            to={{ pathname: '/sign-in', state: { modal: true, returnUrl } }}
          >
            {t('t.login')}
          </Link>
        </div>
        <SocialIcons
          className="sign-up-form__social"
          labelClassName="sign-up-form__social-label"
          label={t('t.register.via.social')}
          layout="vertical"
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'signUpForm',
  validate: validator,
  enableReinitialize: true,
})(SignUpForm);
