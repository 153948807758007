import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Preloader from '../components/Preloader';

const query = gql`
  query GeoLocation {
    geoLocation {
      country
      currency
      language
    }
  }
`;

function withGeoLocation(WrappedComponent, settings = {}) {
  const GeoLocation = ({ data: { loading, geoLocation }, ...props }) => (
    <Choose>
      <When condition={loading && settings.showPreloader}>
        <Preloader />
      </When>
      <When condition={!loading}>
        <WrappedComponent {...props} geoLocation={geoLocation || {}} />
      </When>
    </Choose>
  );

  GeoLocation.propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      geoLocation: PropTypes.shape({
        language: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        country: PropTypes.string,
      }),
    }).isRequired,
  };

  return graphql(query, {
    options: () => ({
      options: {
        fetchPolicy: 'cache-and-network',
      },
    }),
  })(GeoLocation);
}

export default (inputData) =>
  typeof inputData === 'function'
    ? withGeoLocation(inputData)
    : (WrappedComponent) => withGeoLocation(WrappedComponent, inputData);
