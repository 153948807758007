import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import Button from '../Button';
import './SuccessBlock.scss';

const SuccessBlock = ({ className, text, onClick, actionLabel }) => (
  <div className={classNames('success-block', className)}>
    <Icon icon="success" className="success-block__icon" />
    <div className="success-block__text">{text}</div>
    <If condition={onClick}>
      <Button type="button" className="success-block__action" onClick={onClick}>
        {actionLabel}
      </Button>
    </If>
  </div>
);

SuccessBlock.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  actionLabel: PropTypes.string,
};

SuccessBlock.defaultProps = {
  className: null,
  onClick: null,
  actionLabel: null,
};

export default SuccessBlock;
