import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ErrorModal from '../../../components/ErrorModal';
import withLocalization from '../../../utils/withLocalization';
import history from '../../../router/history';
import { ACTIONS } from '../../../constants/notifications';
import SuccessModal from '../../../components/SuccessModal';

const NotificationModals = (props) => {
  const {
    location: { query },
    t,
  } = props;

  return (
    <Fragment>
      <SuccessModal
        title="t.deposit.success.title"
        content="t.deposit.success.content"
        zIndex={1052}
        handleCloseModal={NotificationModals.handleCloseModal}
        isOpen={!!query && query.action === ACTIONS.DEPOSIT && query.success === 'true'}
      />
      <ErrorModal
        handleCloseModal={NotificationModals.handleCloseModal}
        title={t('t.payment.failed.title')}
        content={t('t.payment.failed.desc')}
        isOpen={!!query && query.action === ACTIONS.DEPOSIT && query.success === 'false'}
      />
    </Fragment>
  );
};

NotificationModals.handleCloseModal = () => {
  history.replace(history.location.pathname);
};

NotificationModals.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withLocalization(NotificationModals);
