import { parse } from 'qs';
import trimStart from 'lodash/trimStart';
import intersection from 'lodash/intersection';

const tags = [
  'promo',
  'saff_id',
  'http_referer',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
  'clickid',
  'pid',
  'project',
];

const buildPromoParams = (params, config) =>
  intersection(Object.keys(params), config).reduce((res, key) => {
    const value = params[key];

    return [...res, { name: key, value }];
  }, []);

export default (queryString) => {
  if (!queryString) {
    return {};
  }

  const params = parse(trimStart(queryString, '?'));
  const promoParams = buildPromoParams(params, tags);

  return promoParams.reduce((res, { name, value }) => ({ ...res, [name]: value }), {});
};
