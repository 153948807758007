import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import Amount from '../Amount';
import CustomTable from '../CustomTable';
import './LeaderboardTable.scss';

class LeaderboardTable extends Component {
  static propTypes = {
    leaderboard: PropTypes.arrayOf(
      PropTypes.shape({
        rank: PropTypes.number.isRequired,
        username: PropTypes.string,
        amount: PropTypes.string,
        prizeFund: PropTypes.money.isRequired,
      })
    ).isRequired,
    playerPlace: PropTypes.shape({
      rank: PropTypes.number.isRequired,
      username: PropTypes.string,
      amount: PropTypes.string,
      prizeFund: PropTypes.money,
    }),
    pagination: PropTypes.bool,
    paginationSize: PropTypes.number,
    shortTable: PropTypes.bool,
    className: PropTypes.string,
  };
  static defaultProps = {
    playerPlace: null,
    pagination: false,
    paginationSize: 10,
    shortTable: false,
    className: null,
  };

  state = {
    page: 0,
    paginationSize: this.props.paginationSize,
    pageCount: this.props.leaderboard
      ? Math.round(Math.max(this.props.leaderboard.length / this.props.paginationSize, 1))
      : 0,
  };

  handleChangePage = (page) => {
    this.setState({ page });
  };

  render() {
    const { leaderboard, pagination, shortTable, playerPlace, className } = this.props;
    const { paginationSize, page, pageCount } = this.state;

    const pagePosition = page * paginationSize;
    const tableHeadings = ['t.place', 't.player', 't.score', 't.present'];

    if (shortTable) {
      tableHeadings.splice(2, 4);
    }

    return (
      <Fragment>
        <CustomTable tableHeadings={tableHeadings} className={className}>
          <Choose>
            <When condition={shortTable}>
              {leaderboard.map(({ rank, username }) => (
                <tr key={rank} className={classNames({ active: playerPlace && playerPlace.rank === rank })}>
                  <td>{rank}</td>
                  <td>
                    <Choose>
                      <When condition={username}>{username}</When>
                      <Otherwise>&mdash;</Otherwise>
                    </Choose>
                  </td>
                </tr>
              ))}
            </When>
            <Otherwise>
              {leaderboard
                .slice(pagePosition, pagePosition + paginationSize)
                .map(({ rank, username, amount, prizeFund }) => (
                  <tr key={rank} className={classNames({ active: playerPlace && playerPlace.rank === rank })}>
                    <td>{rank}</td>
                    <td>
                      <Choose>
                        <When condition={username}>{username}</When>
                        <Otherwise>&mdash;</Otherwise>
                      </Choose>
                    </td>
                    <td>
                      <Choose>
                        <When condition={amount}>{amount}</When>
                        <Otherwise>&mdash;</Otherwise>
                      </Choose>
                    </td>
                    <td>
                      <Choose>
                        <When condition={prizeFund}>
                          <Amount integer {...prizeFund} />
                        </When>
                        <Otherwise>&mdash;</Otherwise>
                      </Choose>
                    </td>
                  </tr>
                ))}
            </Otherwise>
          </Choose>
        </CustomTable>
        <If condition={pagination && pageCount !== 1}>
          <div className="leaderboard-pagination">
            {[...Array(pageCount)].map((item, index) => (
              <button
                key={index}
                onClick={() => this.handleChangePage(index)}
                className={classNames('leaderboard-pagination__item', { active: page === index })}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </If>
      </Fragment>
    );
  }
}

export default LeaderboardTable;
