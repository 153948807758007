import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { hot, setConfig } from 'react-hot-loader';
import ApolloProvider from './apollo/ApolloProvider';
import MaintenanceProvider from './maintenance';
import Metrics from './components/Metrics';
import MainRoute from './routes/MainRoute';
import history from './router/history';
import './styles/main.scss';

if (process.env.NODE_ENV === 'development') {
  setConfig({ logLevel: 'debug' });
}

const App = ({ reduxStore }) => (
  <Provider store={reduxStore}>
    <MaintenanceProvider>
      <ApolloProvider>
        <Fragment>
          <Router history={history}>
            <MainRoute />
          </Router>
          <Metrics />
        </Fragment>
      </ApolloProvider>
    </MaintenanceProvider>
  </Provider>
);

App.propTypes = {
  reduxStore: PropTypes.object.isRequired,
};

export default hot(module)(App);
