import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({ toggle, title }) => (
  <div className="modal-header">
    {title && <h4 className="modal-title">{title}</h4>}
    <button
      type="button"
      className="close icon-close"
      data-dismiss="modal"
      aria-label="Close" // eslint-disable-line react/prop-types
      onClick={toggle}
    />
  </div>
);

ModalHeader.propTypes = {
  toggle: PropTypes.func,
  title: PropTypes.string,
};
ModalHeader.defaultProps = {
  toggle: null,
  title: null,
};

export default ModalHeader;
