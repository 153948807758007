import gql from 'graphql-tag';

const lotteriesQuery = gql`
  query lotteries($status: [String], $locale: String) {
    lotteries(status: $status, locale: $locale) {
      id
      title
      startDate
      prizePool {
        amount
        currency
      }
      shortDescription
      status
      endDate
      type
      imageSm
      prizes {
        title
        image
        price {
          amount
          currency
        }
      }
    }
  }
`;

export { lotteriesQuery };
