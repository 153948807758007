import React from 'react';
import PropTypes from 'prop-types';
import NavLink from '../NavLink';
import './NavigationItem.scss';

const NavigationItem = ({ children, to }) => (
  <NavLink className="navigation-item" to={to}>
    {children}
  </NavLink>
);

NavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default NavigationItem;
