import gql from 'graphql-tag';

const signUpQuery = gql`
  query signUpOptions($brandId: String!) {
    options {
      signUp(brandId: $brandId) {
        geoLocation {
          currencyCode
          phoneCode
        }
        post {
          currency {
            list
          }
          passwordPattern
        }
      }
    }
  }
`;

export { signUpQuery };
