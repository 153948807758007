import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'redux-i18n';
import { availableLanguages, defaultLanguage } from '../constants/languages';
import buildQueryString from '../utils/buildQueryString';
import { Localization } from '../router';
import parseJson from '../utils/parseJson';
import { getDomain } from '../config';
import Maintenance from './Maintenance';
import translations from './translations';

const INTERVAL = 30000;

class MaintenanceProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    lang: PropTypes.string.isRequired,
  };

  state = {
    maintenance: false,
    cmsDisabled: false,
  };

  async componentDidMount() {
    this.geoLocation = await this.fetchGeo();
    this.check();

    this.polling = setInterval(this.check, INTERVAL);
  }

  componentWillUnmount() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  }

  get locale() {
    const {
      props: { lang },
      geoLocation: { language: geoLocationLang },
    } = this;

    if (lang) {
      return lang;
    }

    return availableLanguages.indexOf(geoLocationLang) !== -1 ? geoLocationLang : defaultLanguage;
  }

  fetchGeo = async () =>
    fetch('/geo')
      .then((response) => response.text())
      .then((response) => parseJson(response));

  polling = null;
  geoLocation = {};

  check = () => {
    fetch(`/api/cms/domain/${getDomain()}?${buildQueryString({ locale: this.locale })}`)
      .then((response) => response.text())
      .then((resp) => {
        const response = parseJson(resp);

        this.setState({
          maintenance: !Object.prototype.hasOwnProperty.call(response, 'maintenance') || response.maintenance,
          cmsDisabled: false,
        });
      })
      .catch(() => {
        this.setState({ maintenance: true, cmsDisabled: true });
      });
  };

  render() {
    const { maintenance, cmsDisabled } = this.state;
    const { children } = this.props;

    return (
      <Choose>
        <When condition={maintenance && !process.env.NODE_ENV === 'development'}>
          <I18n translations={translations}>
            <Localization match={{ params: { lang: this.locale } }}>
              <Maintenance showBonusForm={!cmsDisabled} />
            </Localization>
          </I18n>
        </When>
        <Otherwise>{children}</Otherwise>
      </Choose>
    );
  }
}

export default connect(({ i18nState: { lang } }) => ({ lang }))(MaintenanceProvider);
