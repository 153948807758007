import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../utils/withLocalization';
import getDeviceType from '../../utils/getDeviceType';
import PostRegistrationModal from './PostRegistrationModal';
import { optInMutation } from '../../apollo/mutations';

const bonusCampaignsQuery = gql`
  query bonusCampaigns($playerUUID: String!, $deviceType: String, $locale: String) {
    bonusCampaigns(playerUUID: $playerUUID, deviceType: $deviceType, locale: $locale, activateOnReg: true) {
      data {
        campaignUUID
        parentCampaignUUID
        title
        description
        image
      }
    }
  }
`;

export default compose(
  withRouter,
  connect(({ auth: { uuid }, i18nState: { lang: locale } }) => ({ uuid, locale })),
  graphql(bonusCampaignsQuery, {
    options: ({ uuid, locale }) => ({
      variables: {
        playerUUID: uuid,
        deviceType: getDeviceType(),
        locale,
      },
      skip: !uuid,
    }),
    name: 'bonusCampaigns',
  }),
  graphql(optInMutation, {
    name: 'optInMutation',
  }),
  withLocalization
)(PostRegistrationModal);
