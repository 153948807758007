import React from 'react';
import PropTypes from 'prop-types';

const SliderArrowRight = ({ onClick }) => (
  <button onClick={onClick} className="icon-slider-arrow-right calendar-arrow calendar-arrow--right" />
);

SliderArrowRight.propTypes = {
  onClick: PropTypes.func,
};
SliderArrowRight.defaultProps = {
  onClick: null,
};

export default SliderArrowRight;
