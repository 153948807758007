import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { persistStore, autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk';
import isEmpty from 'lodash/isEmpty';
import crosstabSync from 'redux-persist-crosstab';
import makeRootReducer from './reducers';
import { actionCreators as affiliateActionCreators } from '../redux/modules/affiliate';
import { actionCreators as promoActionCreators } from '../redux/modules/promo';
import getAffiliateData from '../utils/affiliation';
import getPromoData from '../utils/promo';
import config from '../config';

export default (initialState = {}, onComplete) => {
  const middleware = [thunk, apiMiddleware];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [autoRehydrate()];
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  const persist = persistStore(store, config.middlewares.persist, () => {
    const { affiliate } = store.getState();

    if (isEmpty(affiliate)) {
      store.dispatch(affiliateActionCreators.setAffiliateFields(getAffiliateData(window.location.search)));
    }

    store.dispatch(promoActionCreators.setPromoFields(getPromoData(window.location.search)));

    onComplete(store);
  });

  crosstabSync(persist, config.middlewares.persist);

  store.asyncReducers = {};
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }
};
