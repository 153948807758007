import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import CustomTable from '../CustomTable';
import withLocalization from '../../utils/withLocalization';
import './TableLoadMore.scss';

const SHORT_LIST_SIZE = 10;

class TableLoadMore extends Component {
  static propTypes = {
    leaderboard: PropTypes.calendarLeaderboardEntity.isRequired,
    t: PropTypes.func.isRequired,
    showMoreButton: PropTypes.bool,
    uuid: PropTypes.string,
  };
  static defaultProps = {
    showMoreButton: false,
    uuid: null,
  };

  state = {
    fullList: false,
  };

  handleClickShowAll = () => {
    this.setState({ fullList: true });
  };

  render() {
    const { leaderboard, t, showMoreButton, uuid } = this.props;
    const { fullList } = this.state;

    const leaders = fullList ? leaderboard : [...leaderboard].splice(0, SHORT_LIST_SIZE);
    const showFullListButton = showMoreButton && !fullList && leaderboard.length > SHORT_LIST_SIZE;
    const tableHeadings = ['t.place', 't.player', 't.score', 't.present'];
    const findPlayerInLeaderboard = leaderboard.find((i) => i.playerUUID === uuid);

    return (
      <Fragment>
        <div className="table-load-more">
          <CustomTable tableHeadings={tableHeadings}>
            {leaders.map(({ rank, login, score, prize, playerUUID }) => (
              <tr key={rank} className={classNames({ active: playerUUID === uuid && score > 0 })}>
                <td>{rank}</td>
                <td>
                  <Choose>
                    <When condition={score > 0}>{login}</When>
                    <Otherwise>&mdash;</Otherwise>
                  </Choose>
                </td>
                <td>{score}</td>
                <td>
                  <Choose>
                    <When condition={prize}>{prize}</When>
                    <Otherwise>&mdash;</Otherwise>
                  </Choose>
                </td>
              </tr>
            ))}
          </CustomTable>
        </div>
        <If condition={showFullListButton}>
          <div className="text-center mt-3">
            <button type="button" onClick={this.handleClickShowAll} className="table-load-more__action">
              {t('t.table_load_more.expand')}
              <div className="icon-angle-right table-load-more__action-icon" />
            </button>
          </div>
        </If>
        <If condition={uuid && !findPlayerInLeaderboard}>
          <div className="table-load-more__not-qualified">{t('t.table_load_more.not_qualified')}</div>
        </If>
      </Fragment>
    );
  }
}

export default withLocalization(TableLoadMore);
