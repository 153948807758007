import gql from 'graphql-tag';

const calendarLeaderboardQuery = gql`
  query calendarLeaderboard($locale: String!) {
    calendarLeaderboard(locale: $locale) {
      startDate
      endDate
      places {
        rank
        login
        score
        prize
        playerUUID
      }
    }
  }
`;

export { calendarLeaderboardQuery };
