import gql from 'graphql-tag';

const tournamentsQuery = gql`
  query tournaments($status: [String]!, $locale: String!) {
    tournaments(status: $status, locale: $locale) {
      alias
      id
      prizeType
      title
      startDate
      prizePool {
        amount
        currency
      }
      places {
        amount
        currency
      }
      status
      endDate
      icon
      shortDescription
    }
  }
`;

export { tournamentsQuery };
