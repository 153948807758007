import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import './ErrorBlock.scss';

const ErrorBlock = ({ children, className }) => (
  <div className={classNames('col-sm-8 mx-sm-auto error-block', className)}>
    <Icon icon="attention" className="error-block__icon" />
    <div className="error-block__text">{children}</div>
  </div>
);

ErrorBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ErrorBlock.defaultProps = {
  className: '',
};

export default ErrorBlock;
