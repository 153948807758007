import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Preloader from '../Preloader';
import './ImageContainer.scss';

class ImageContainer extends PureComponent {
  static propTypes = {
    src: PropTypes.string,
    className: PropTypes.string,
  };
  static defaultProps = {
    src: '',
    className: '',
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
  }

  handleLoad = () => {
    this.loadingTimeout = setTimeout(() => {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }, 300);
  };

  mounted = false;
  loadingTimeout = null;

  render() {
    const { className, alt, ...props } = this.props;
    const { loading } = this.state;

    return (
      <Fragment>
        <img {...props} className={classNames(className, { 'd-none': loading })} alt={alt} onLoad={this.handleLoad} />
        <If condition={loading}>
          <Preloader className="image-container" label={false} />
        </If>
      </Fragment>
    );
  }
}

export default ImageContainer;
