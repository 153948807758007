import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import { createValidator } from '../utils/validator';
import { Input } from '../components/Form';
import withLocalization from '../utils/withLocalization';
import { LoadingButton, Button } from '../components';

const validator = createValidator({
  email: 'required|email',
  phone: 'required|numeric',
});

class MaintenanceForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
  };

  handleSubmit = (data) => {
    fetch(`/api/cms/maintenance?${stringify(data)}`);
    this.props.reset();
  };

  render() {
    const { submitting, t, handleSubmit, submitSucceeded } = this.props;

    return (
      <Choose>
        <When condition={submitSucceeded}>
          <div className="maintenance__description">{t('t.maintenance_form.submitting_text')}</div>
        </When>
        <Otherwise>
          <form className="maintenance__form text-left" onSubmit={handleSubmit(this.handleSubmit)}>
            <Field name="email" type="email" label={t('t.enter.your.email')} component={Input} hideErrorMessage />
            <Field name="phone" type="phone" label={t('t.enter.your.phone')} component={Input} hideErrorMessage />
            <Button
              type="submit"
              disabled={submitting}
              loading={submitting}
              tag={LoadingButton}
              className="py-2 maintenance__button"
            >
              {t('t.maintenance.get.present.btn')}
            </Button>
          </form>
        </Otherwise>
      </Choose>
    );
  }
}

export default withLocalization(
  reduxForm({
    form: 'signUpForm',
    validate: validator,
  })(MaintenanceForm)
);
