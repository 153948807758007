import React from 'react';
import PropTypes from 'prop-types';
import withLocalization from '../../utils/withLocalization';
import InfoModal from '../InfoModal';
import ErrorBlock from '../ErrorBlock';

const ErrorModal = ({ title, content, handleCloseModal, isOpen, t, zIndex }) => (
  <InfoModal
    title={t(title)}
    onClose={handleCloseModal}
    zIndex={zIndex}
    onButtonClick={handleCloseModal}
    isOpen={isOpen}
  >
    <ErrorBlock>{t(content)}</ErrorBlock>
  </InfoModal>
);

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
};

ErrorModal.defaultProps = {
  zIndex: 1052,
};

export default withLocalization(ErrorModal);
