import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

const Metrics = (props) => {
  const {
    domainData: { loading, domainData },
  } = props;

  if (loading) {
    return null;
  }

  const { yaMetrics, gaWebmaster, yaWebmaster, gaAnalytics } = get(domainData, 'data') || {};
  return (
    <Fragment>
      <Helmet>
        <meta name="google-site-verification" content={gaWebmaster} />
        <meta name="yandex-verification" content={yaWebmaster} />
        <If condition={yaMetrics}>
          <script type="text/javascript">
            {`
            (function (d, w, c) {
                (w[c] = w[c] || []).push(() => {
                    try {
                        w.yaCounter${yaMetrics} = new Ya.Metrika({
                            id: '${yaMetrics}',
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true
                        });
                    } catch (e) { }
                });

                let n = d.getElementsByTagName('script')[0],
                    s = d.createElement('script'),
                    f = function () { n.parentNode.insertBefore(s, n); };
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://mc.yandex.ru/metrika/watch.js';

                if (w.opera == '[object Opera]') {
                    d.addEventListener('DOMContentLoaded', f, false);
                } else { f(); }
            }(document, window, 'yandex_metrika_callbacks'))
          `}
          </script>
        </If>
        <If condition={gaAnalytics}>
          <script>
            {`
            window.ga=window.ga||function(){(ga.q = ga.q || []).push(arguments)};ga.l=+new Date;
            ga('create', '${gaAnalytics}', 'auto');
            ga('send', 'pageview');`}
          </script>
          <script async src="https://www.google-analytics.com/analytics.js" />
        </If>
      </Helmet>
      <If condition={yaMetrics}>
        <img src={`https://mc.yandex.ru/watch/${yaMetrics}`} style={{ position: 'absolute', left: '-9999px' }} alt="" />
      </If>
    </Fragment>
  );
};

Metrics.propTypes = {
  domainData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    domainData: PropTypes.shape({
      yaMetrics: PropTypes.string,
      gaAnalytics: PropTypes.string,
      gaWebmaster: PropTypes.string,
      yaWebmaster: PropTypes.string,
    }),
  }).isRequired,
};

export default Metrics;
