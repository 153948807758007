import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import ExitToLobbyModal from './ExitToLobbyModal';
import { getBrandId } from '../../../../config';
import { getDeviceType, withLocalization } from '../../../../utils';
import withGeoLocation from '../../../../utils/withGeoLocation';

const gamesQuery = gql`
  query ExitToLobbyView($gameType: String!, $page: Int!, $brandId: String!, $country: String) {
    gamesNg(type: $gameType, page: $page, size: 4, brandId: $brandId, countryCode: $country) {
      page
      last
      content {
        funModeSupported
        slug
        providerId
        restricted
        providerAlias
        gameId
        isFavorite
        _id
        fullGameName
        internalGameId
        image
      }
    }
  }
`;

export default compose(
  connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
  withGeoLocation,
  graphql(gamesQuery, {
    options: ({ uuid, geoLocation: { country } }) => ({
      fetchPolicy: 'network-only',
      variables: {
        page: 0,
        brandId: getBrandId(),
        playerUUID: uuid,
        gameType: getDeviceType(),
        country,
      },
    }),
    name: 'games',
  }),
  withLocalization
)(ExitToLobbyModal);
