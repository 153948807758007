import React from 'react';
import PropTypes from 'prop-types';
import { footerMenu } from '../../../constants/menu';
import withLocalization from '../../../utils/withLocalization';
import NavigationItem from '../../../components/NavigationItem';
import './FooterNav.scss';

const FooterNav = ({ t }) => (
  <nav className="footer-nav">
    {footerMenu.map(({ label, url }) => (
      <NavigationItem key={label} to={url}>
        {t(label)}
      </NavigationItem>
    ))}
  </nav>
);

FooterNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withLocalization(FooterNav);
