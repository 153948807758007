import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import FormGroup from '../FormGroup';

class Input extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.shape({
      valid: PropTypes.bool,
      touched: PropTypes.bool,
      dirty: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }).isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    maxLength: PropTypes.number,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    autoComplete: PropTypes.string,
    isRequired: PropTypes.bool,
    customError: PropTypes.string,
    mask: PropTypes.string,
    horizontal: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelClassName: PropTypes.string,
    hideErrorMessage: PropTypes.bool,
    className: PropTypes.string,
    inputWrapperClassName: PropTypes.string,
    additionalBlock: PropTypes.element,
    addon: PropTypes.element,
    completed: PropTypes.bool,
    hideIcons: PropTypes.bool,
    maskChar: PropTypes.string,
    inputClassName: PropTypes.string,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    type: null,
    placeholder: null,
    disabled: false,
    id: null,
    maxLength: null,
    inputRef: null,
    autoComplete: null,
    isRequired: false,
    customError: null,
    mask: null,
    horizontal: false,
    label: null,
    labelClassName: null,
    hideErrorMessage: false,
    className: null,
    inputWrapperClassName: 'col-md',
    additionalBlock: null,
    addon: null,
    completed: false,
    hideIcons: false,
    maskChar: '_',
    inputClassName: null,
    onFocus: null,
    onChange: null,
  };

  handleChange = (e) => {
    const { input, onChange } = this.props;
    input.onChange(e);

    if (onChange) {
      onChange(e);
    }
  };

  renderField = () => {
    const {
      input,
      meta: { valid, touched, error, dirty, initial },
      type,
      placeholder,
      disabled,
      id,
      maxLength,
      inputRef,
      autoComplete,
      customError,
      mask,
      addon,
      completed,
      hideIcons,
      maskChar,
      inputClassName,
      onFocus,
    } = this.props;

    const inputClass = classNames(
      'form-control',
      inputClassName,
      { 'has-danger': customError || (touched && error) },
      { completed },
      { dirty: dirty && input.value },
      { valid: (touched && valid) || (initial && !touched) },
      { 'no-icons': hideIcons }
    );

    let inputField = (
      <input
        {...input}
        type={input.type || type}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        ref={inputRef}
        autoComplete={autoComplete}
        className={inputClass}
        onFocus={onFocus}
        onChange={(e) => this.handleChange(e)}
      />
    );

    if (addon) {
      inputField = (
        <div className="input-group">
          {inputField}
          <span className="input-group-append">{addon}</span>
        </div>
      );
    }

    if (mask) {
      inputField = (
        <InputMask
          {...input}
          className={inputClass}
          inputRef={inputRef}
          mask={mask}
          placeholder={placeholder}
          maskChar={maskChar}
        />
      );
    }

    return inputField;
  };

  render() {
    const {
      meta: { touched, error },
      label,
      labelClassName,
      customError,
      hideErrorMessage,
      isRequired,
      horizontal,
      className,
      inputWrapperClassName,
      additionalBlock,
    } = this.props;

    return (
      <FormGroup
        touched={touched}
        error={error}
        label={label}
        labelClassName={labelClassName}
        customError={customError}
        hideErrorMessage={hideErrorMessage}
        required={isRequired}
        className={className}
        horizontal={horizontal}
        inputWrapperClassName={inputWrapperClassName}
        additionalBlock={additionalBlock}
      >
        {this.renderField()}
      </FormGroup>
    );
  }
}

export default Input;
