import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, withRouter, matchPath } from 'react-router-dom';
import { getUrlWithLang } from '../../utils/url';
import history from '../../router/history';

class NavLink extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    to: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    staticContext: PropTypes.any,
  };

  static defaultProps = {
    staticContext: null,
  };

  static contextTypes = {
    previousLocation: PropTypes.object,
  };

  get to() {
    const {
      to,
      match: {
        params: { lang },
      },
    } = this.props;

    let linkTo = {};

    if (typeof to === 'object') {
      linkTo = { ...to };
    } else {
      linkTo.pathname = to;
    }

    const { location } = history;

    linkTo.state = {
      ...linkTo.state,
      prevLocation: {
        ...location,
        state: {
          ...location.state,
          prevLocation: null,
        },
      },
    };
    linkTo.pathname = getUrlWithLang(linkTo.pathname, lang);

    return linkTo;
  }

  isActive = (match) => {
    const { location } = this.props;

    if (location.state && location.state.modal) {
      const path = this.to.pathname && this.to.pathname.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

      return !!matchPath(location.pathname, { path }) || !!match;
    }

    return !!match;
  };

  render() {
    const { match, location, history, staticContext, children, ...props } = this.props;
    const { previousLocation } = this.context;

    return (
      <RouterLink
        {...props}
        isActive={this.isActive}
        location={location.state && location.state.modal ? previousLocation : location}
        to={this.to}
      >
        {children}
      </RouterLink>
    );
  }
}

export default withRouter(NavLink);
