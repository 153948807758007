import React from 'react';
import PropTypes from 'prop-types';

const SliderArrowLeft = ({ onClick }) => (
  <button onClick={onClick} className="icon-slider-arrow-left calendar-arrow calendar-arrow--left" />
);

SliderArrowLeft.propTypes = {
  onClick: PropTypes.func,
};
SliderArrowLeft.defaultProps = {
  onClick: null,
};

export default SliderArrowLeft;
