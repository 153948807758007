import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import onClickOutside from 'react-onclickoutside';
import Amount from '../Amount';
import Link from '../Link';
import withLocalization from '../../utils/withLocalization';
import ImageContainer from '../ImageContainer';
import withModals from '../../utils/withModals';
import ErrorModal from '../ErrorModal';
import isMobile from '../../utils/isMobile';
import Button from '../Button';
import './GameItem.scss';

class GameItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    internalGameId: PropTypes.string.isRequired,
    modals: PropTypes.shape({
      error: PropTypes.shape({
        show: PropTypes.func,
      }),
    }).isRequired,
    fullGameName: PropTypes.string,
    image: PropTypes.string,
    isFavorite: PropTypes.bool,
    logged: PropTypes.bool,
    hidden: PropTypes.bool,
    removeFromFavorites: PropTypes.func,
    addToFavorites: PropTypes.func,
    label: PropTypes.node,
    labelColor: PropTypes.oneOf(['new', 'jackpot', 'gameof']),
    jackpot: PropTypes.string,
    funModeSupported: PropTypes.bool,
    slug: PropTypes.string.isRequired,
    providerId: PropTypes.string.isRequired,
    className: PropTypes.string,
    onGameButtonClick: PropTypes.func,
  };

  static defaultProps = {
    fullGameName: '',
    image: '',
    isFavorite: false,
    hidden: false,
    logged: false,
    removeFromFavorites: null,
    addToFavorites: null,
    label: null,
    labelColor: null,
    jackpot: null,
    funModeSupported: false,
    className: 'col-6 col-md-4 col-lg-3 col-xl-2',
    onGameButtonClick: null,
  };

  state = {
    overlayIsActive: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClickAddFavorite = () => {
    const { internalGameId, isFavorite, addToFavorites, removeFromFavorites } = this.props;

    if (isFavorite) {
      removeFromFavorites({ internalGameId });
    } else {
      addToFavorites({ internalGameId });
    }
  };

  handleClick = (e) => {
    const {
      hidden,
      modals: { error },
      onGameButtonClick,
    } = this.props;

    if (onGameButtonClick) {
      onGameButtonClick();
    }

    if (hidden) {
      e.preventDefault();
      error.show({ title: 't.game.restricted.title', content: 't.game.restricted.desc' });
    }
  };

  handleClickOutside = () => {
    if (isMobile.any() && this.state.overlayIsActive) {
      this.setState({ overlayIsActive: false });
    }
  };

  handleShowOverlay = () => {
    if (isMobile.any()) {
      if (this.state.overlayIsActive) {
        this.handleClickOutside();
      } else {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({ overlayIsActive: true });
          }
        }, 50);
      }
    }
  };

  mounted = false;

  render() {
    const {
      t,
      fullGameName,
      funModeSupported,
      image,
      isFavorite,
      logged,
      label,
      labelColor,
      jackpot,
      slug,
      providerId,
      className,
      addToFavorites,
      removeFromFavorites,
    } = this.props;
    const { overlayIsActive } = this.state;

    const gameLink = logged
      ? `/game/${providerId}/${slug}/real`
      : {
          pathname: '/sign-up',
          state: { returnUrl: `/game/${providerId}/${slug}/real`, modal: true },
        };
    const demoGameLink = logged
      ? `/game/${providerId}/${slug}/demo`
      : {
          pathname: '/sign-up',
          state: { returnUrl: `/game/${providerId}/${slug}/demo`, modal: true },
        };

    return (
      <div className={classNames('game-item', className)}>
        <div role="presentation" className="game-item__wrapper" onClick={this.handleShowOverlay}>
          <ImageContainer className="game-item__image" src={image} alt={fullGameName} />
          <If condition={label}>
            <span className={`game-item__label ${labelColor}`}>{label}</span>
          </If>
          <If condition={jackpot}>
            <Amount tag="div" className="game-item__jackpot" amount={jackpot} currency="RUB" />
          </If>
          <div className={classNames('game-item__overlay', { open: overlayIsActive })}>
            <div className="row no-gutters game-item__actions">
              <Button tag={Link} className="game-item__play" onClick={this.handleClick} to={gameLink}>
                {t('t.play')}
              </Button>
              <If condition={funModeSupported}>
                <Button tag={Link} className="game-item__demo" outline onClick={this.handleClick} to={demoGameLink}>
                  {t('t.demo')}
                </Button>
              </If>
            </div>
            <If condition={logged && addToFavorites && removeFromFavorites}>
              <button
                type="button"
                className={classNames('icon-favorite game-item__favorite', { active: isFavorite })}
                onClick={this.handleClickAddFavorite}
              />
            </If>
          </div>
        </div>
        <div className="game-item__name">{fullGameName}</div>
      </div>
    );
  }
}

export default compose(
  withLocalization,
  withModals({
    error: ErrorModal,
  }),
  onClickOutside
)(GameItem);
