import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import './BackToTop.scss';

class BackToTop extends PureComponent {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (
      document.documentElement.scrollTop > document.documentElement.clientHeight ||
      document.body.scrollTop > document.body.clientHeight
    ) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    const { isVisible } = this.state;

    return (
      <button type="button" className={classNames('back-to-top', { isVisible })} onClick={this.scrollToTop}>
        <Icon icon="arrow-up" />
      </button>
    );
  }
}

export default BackToTop;
