import React from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import withLocalization from '../../utils/withLocalization';
import Amount from '../Amount';
import Link from '../Link';
import Button from '../Button';
import Icon from '../Icon';
import './EventItem.scss';

const EventItem = ({
  children,
  className,
  icon,
  title,
  titleClassName,
  image,
  prizePool,
  link,
  actionClassName,
  prizePoolClassName,
  t,
}) => (
  <div className={classNames('event-item', className)}>
    <If condition={icon}>
      <Icon icon={`${icon}-cup`} className="event-item__icon" />
    </If>
    <p className={classNames('event-item__title', titleClassName)}>{title}</p>
    <div className="event-item__image" style={{ backgroundImage: `url(${image})` }}>
      <div className="event-item__overlay">
        <div className="event-item__prizePoolTitle">{t('t.prizepoll')}:</div>
        <Amount integer tag="div" className={classNames('event-item__prizePool', prizePoolClassName)} {...prizePool} />
      </div>
    </div>
    {children}
    <div className={classNames('event-item__action', actionClassName)}>
      <Button className="event-item__actionBtn" tag={Link} to={link} outline target="_blank">
        {t('t.join')}
      </Button>
    </div>
  </div>
);

EventItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  image: PropTypes.string,
  prizePool: PropTypes.money.isRequired,
  link: PropTypes.string.isRequired,
  actionClassName: PropTypes.string,
  prizePoolClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

EventItem.defaultProps = {
  className: null,
  icon: null,
  titleClassName: null,
  image: '',
  actionClassName: null,
  prizePoolClassName: null,
};

export default withLocalization(EventItem);
