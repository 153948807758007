import React from 'react';
import PropTypes from 'prop-types';
import MaintenanceForm from './MaintenanceForm';
import withLocalization from '../utils/withLocalization';
import { Icon } from '../components';
import './Maintenance.scss';

const Maintenance = ({ t, showBonusForm }) => (
  <div className="maintenance">
    <div className="maintenance__title">{t('t.maintenance.title')}</div>
    <div className="maintenance__description">{t('t.maintenance.description')}</div>
    <div className="maintenance__content">
      <Icon icon="gift-filled" className="maintenance__icon" />
      <If condition={showBonusForm}>
        <MaintenanceForm />
      </If>
    </div>
    <div className="maintenance__footer">{t('t.maintenance.footer')}</div>
  </div>
);

Maintenance.propTypes = {
  t: PropTypes.func.isRequired,
  showBonusForm: PropTypes.bool,
};
Maintenance.defaultProps = {
  showBonusForm: true,
};

export default withLocalization(Maintenance);
