import React from 'react';
import PropTypes from 'prop-types';

const SliderArrowNext = ({ onClick }) => (
  <div className="slider-arrows slider-arrows--right">
    <button onClick={onClick} className="icon-angle-right slider-arrows__content" />
  </div>
);

SliderArrowNext.propTypes = {
  onClick: PropTypes.func,
};
SliderArrowNext.defaultProps = {
  onClick: null,
};

export default SliderArrowNext;
