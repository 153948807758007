const navigation = [
  { label: 't.games', url: '/games/all', key: 'games' },
  { label: 't.live_casino', url: '/games/live_casino', key: 'live_casino' },
  { label: 't.promos', url: '/promo', key: 'promos' },
  { label: 't.calendar', url: '/calendar', key: 'calendar' },
  { label: 't.tournaments', url: '/tournaments', key: 'tournaments' },
  { label: 't.lotteries', url: '/lotteries', key: 'lotteries' },
];

const footerMenu = [
  { label: 't.rules', url: '/rules' },
  { label: 't.responsible.gaming', url: '/responsible' },
  { label: 't.contacts', url: '/contacts' },
  { label: 't.privacy', url: '/privacy' },
  { label: 't.refund_policy', url: '/refund' },
];

const playerMenu = [
  {
    label: 't.profile',
    url: { pathname: '/player/profile', state: { modal: true } },
    icon: 'icon-user',
  },
  {
    label: 't.cashier',
    url: { pathname: '/player/cashbox', state: { modal: true } },
    icon: 'icon-currency',
  },
  {
    label: 't.gifts',
    url: { pathname: '/player/bonuses', state: { modal: true } },
    icon: 'icon-gift',
  },
];

const tournamentsMenu = [
  {
    label: 't.tournament.CURRENT',
    url: '/tournaments/active',
  },
  {
    label: 't.tournament.FINISHED',
    url: '/tournaments/finished',
  },
];

const lotteriesMenu = [
  {
    label: 't.lottery.CURRENT',
    url: '/lotteries/active',
  },
  {
    label: 't.lottery.FINISHED',
    url: '/lotteries/finished',
  },
];

export { navigation, footerMenu, playerMenu, tournamentsMenu, lotteriesMenu };
