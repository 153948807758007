import React, { PureComponent } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from '../../../constants/prop-types';
import SlickSlider from '../../../components/SlickSlider';
import SliderArrowLeft from './SliderArrowLeft';
import SliderArrowRight from './SliderArrowRight';
import responsiveSettings from './constants';
import { Link, NavLink, AmountTemplate, Button, Amount, Timer, ShortPreloader } from '../../../components';
import TableLoadMore from '../../../components/TableLoadMore';
import getDaysInMonth from '../../../utils/getDaysInMonth';
import { STATUSES } from '../../../constants/statuses';
import './Calendar.scss';

class Calendar extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    lotteries: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      lotteries: PropTypes.lotteriesEntity,
    }),
    tournaments: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      tournaments: PropTypes.tournamentsEntity,
    }),
    leaderboard: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      calendarLeaderboard: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        places: PropTypes.calendarLeaderboardEntity,
      }),
    }),
    locale: PropTypes.string.isRequired,
    uuid: PropTypes.string,
  };
  static defaultProps = {
    lotteries: [],
    tournaments: [],
    leaderboard: [],
    uuid: null,
  };
  static getDerivedStateFromProps(props, state) {
    if (state.currentLocale !== props.locale) {
      return {
        currentLocale: props.locale,
        activeDate: moment().format('DD.MM'),
        arrayOfDays: getDaysInMonth(moment().month() + 1, moment().year()),
        currentDate: moment().format('DD.MM'),
        currentMonth: moment.months(moment().month()),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentLocale: props.locale,
      activeDate: moment().format('DD.MM'),
      selectedEvent: null,
      arrayOfDays: getDaysInMonth(moment().month() + 1, moment().year()),
      currentDate: moment().format('DD.MM'),
      currentMonth: moment.months(moment().month()),
    };
  }

  handleSlideChange = (currentSlideId) => {
    this.setState({
      activeDate: moment(moment().set('date', currentSlideId + 1)).format('DD.MM'),
      selectedEvent: null,
    });
  };

  handleEventSelect = (id) => {
    this.setState({ selectedEvent: id });
  };

  renderEventInfo = (item) => {
    const { t } = this.props;
    const eventLink = item.type ? `/lotteries/${item.id}` : `/tournaments/${item.id}`;

    return (
      <div className="calendar__event-info">
        <div className="calendar__event-info-title">
          <AmountTemplate value={item.title} />
        </div>
        <AmountTemplate value={item.shortDescription} />
        <br />
        <NavLink key={item.id} to={eventLink} className="calendar__event-info-btn">
          {t('t.calendar.more')}
        </NavLink>
      </div>
    );
  };

  render() {
    const {
      t,
      lotteries: { loading: lotteriesLoading, lotteries },
      tournaments: { loading: tournamentsLoading, tournaments },
      leaderboard: { loading: leaderboardLoading, calendarLeaderboard: leaderboard },
      uuid,
    } = this.props;
    const { activeDate, selectedEvent, arrayOfDays, currentDate, currentMonth } = this.state;

    if (lotteriesLoading && tournamentsLoading && leaderboardLoading) {
      return <ShortPreloader type="custom" />;
    }

    let eventsArray = [];

    if (tournaments && lotteries) {
      eventsArray = [...tournaments, ...lotteries];

      eventsArray = eventsArray.filter(
        (item) =>
          moment(item.startDate).format('DD.MM') === activeDate ||
          moment(activeDate, 'DD.MM').isBetween(moment(item.startDate), moment(item.endDate))
      );
    }

    const { startDate, endDate, places } = leaderboard;

    const timerDelay = moment
      .duration(moment(startDate && moment(startDate).isAfter() ? startDate : endDate).diff())
      .asMilliseconds();

    return (
      <div className="calendar">
        <h1 className="calendar__title">{t('t.calendar.title')}</h1>
        <h5 className="calendar__label">{t('t.calendar.description-label')}</h5>
        <p className="calendar__description">{t('t.calendar.description')}</p>
        <div className="calendar__heading">{currentMonth}</div>
        <SlickSlider
          slidesToShow={7}
          arrows
          focusOnSelect
          initialSlide={moment().date() - 1}
          prevArrow={<SliderArrowLeft />}
          nextArrow={<SliderArrowRight />}
          afterChange={(current) => this.handleSlideChange(current)}
          responsive={responsiveSettings}
        >
          {arrayOfDays.map((item) => (
            <div key={item}>
              <div className={classNames('calendar__item', { current: item === currentDate })}>{item}</div>
            </div>
          ))}
        </SlickSlider>
        <Choose>
          <When condition={eventsArray && eventsArray.length === 0}>
            <div className="calendar__no-events">{t('t.calendar.no-events')}</div>
          </When>
          <Otherwise>
            <div className="row calendar__events">
              {eventsArray.map((item) => {
                const eventLink = item.type ? `/lotteries/${item.id}` : `/tournaments/${item.id}`;

                return (
                  <div key={item.id} className="col-md-4 mb-3 mb-md-0">
                    <div
                      onClick={() => this.handleEventSelect(item.id)}
                      className={classNames(
                        'calendar__event',
                        { active: item.id === selectedEvent },
                        { finished: item.status === STATUSES.FINISHED }
                      )}
                    >
                      <img className="calendar__event-image" src={item.icon || item.imageSm} alt={item.title} />
                      <div className="calendar__event-title">
                        <AmountTemplate value={item.title} />
                      </div>
                      <div className="calendar__event-label">{t('t.calendar.prizepool')}</div>
                      <Amount tag="div" className="calendar__event-prize" integer {...item.prizePool} />
                      <Choose>
                        <When condition={item.status === STATUSES.PENDING}>{t('t.calendar.event-starts')}</When>
                        <When condition={item.status === STATUSES.ACTIVE}>{t('t.calendar.event-lasts')}</When>
                        <Otherwise>{t('t.calendar.event-finished')}</Otherwise>
                      </Choose>
                      <div className="calendar__event-time">
                        <Choose>
                          <When condition={item.status === STATUSES.PENDING}>
                            {moment.utc(item.startDate).local().format('DD-MM-YYYY HH:mm:ss')}
                          </When>
                          <Otherwise>{moment.utc(item.endDate).local().format('DD-MM-YYYY HH:mm:ss')}</Otherwise>
                        </Choose>
                      </div>
                      <If condition={item.status !== STATUSES.FINISHED}>
                        <Button key={item.id} to={eventLink} className="calendar__event-btn" outline tag={NavLink}>
                          {t('t.calendar.participate')}
                        </Button>
                      </If>
                    </div>
                    <If condition={selectedEvent === item.id}>
                      <div key={item.id} className="d-md-none">
                        {this.renderEventInfo(item)}
                      </div>
                    </If>
                  </div>
                );
              })}
              <If condition={selectedEvent}>
                {eventsArray
                  .filter((item) => item.id === selectedEvent)
                  .map((item) => (
                    <div key={item.id} className="d-none d-md-block col-12">
                      {this.renderEventInfo(item)}
                    </div>
                  ))}
              </If>
            </div>
          </Otherwise>
        </Choose>
        <div className="calendar__timer-wrapper">
          <Timer
            className="calendar__timer"
            label={moment(startDate).isAfter() ? 't.calendar.time-to-start' : 't.calendar.time-left'}
            delay={timerDelay}
          />
        </div>
        <If condition={leaderboard}>
          <h5 className="calendar__label mt-4 mt-md-5">{t('t.calendar.leaderboard')}</h5>
          <TableLoadMore leaderboard={places} showMoreButton uuid={uuid} />
        </If>
        <div className="text-center mt-4">
          <Button
            className="calendar__deposit-btn"
            tag={Link}
            to={{ pathname: '/player/cashbox/deposit', state: { modal: true } }}
          >
            {t('t.calendar.deposit-btn')}
          </Button>
        </div>
      </div>
    );
  }
}

export default Calendar;
