import gql from 'graphql-tag';

const addToFavoritesMutation = gql`
  mutation addToFavorites($internalGameId: String!, $playerUUID: String!) {
    game {
      addToFavorites(playerUUID: $playerUUID, internalGameId: $internalGameId) {
        isFavorite
        _id
      }
    }
  }
`;

const removeFromFavoritesMutation = gql`
  mutation removeFromFavorites($internalGameId: String!, $playerUUID: String!) {
    game {
      removeFromFavorites(playerUUID: $playerUUID, internalGameId: $internalGameId) {
        isFavorite
        _id
      }
    }
  }
`;

export { addToFavoritesMutation, removeFromFavoritesMutation };
