import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import WinnersHorizontal from './WinnersHorizontal';
import WinnersVertical from './WinnersVertical';
import './Winners.scss';

function Winners({ onCloseClick, vertical, logged, data }) {
  const winners = get(data, 'winners', []);

  return (
    <Choose>
      <When condition={vertical}>
        <WinnersVertical items={winners} logged={logged} />
      </When>
      <Otherwise>
        <WinnersHorizontal items={winners} onCloseClick={onCloseClick} logged={logged} />
      </Otherwise>
    </Choose>
  );
}

Winners.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    games: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          fullGameName: PropTypes.string.isRequired,
          image: PropTypes.string,
          slug: PropTypes.string.isRequired,
          providerId: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }).isRequired,
  onCloseClick: PropTypes.func,
  vertical: PropTypes.bool,
  logged: PropTypes.bool.isRequired,
};

Winners.defaultProps = {
  onCloseClick: null,
  vertical: false,
};

export default Winners;
