import gql from 'graphql-tag';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { parseJson, renderHTML } from '../../utils';
import { getBaseCurrency } from '../../config';
import withGeoLocation from '../../utils/withGeoLocation';

const profileCurrencyQuery = gql`
  query getProfile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        currency
      }
    }
  }
`;

const AmountTemplate = ({ value, profile, renderAsHtml, geoLocation: { currency: geoCurrency } }) => {
  const currency = get(profile, 'player.data.currency', geoCurrency || getBaseCurrency());

  if (!value || !currency) {
    return null;
  }
  const result = value.replace(/\{(.*?)\}/g, (data) => get(parseJson(data), currency, value));

  return renderAsHtml ? renderHTML(result) : result;
};

AmountTemplate.propTypes = {
  value: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    player: PropTypes.shape({
      data: PropTypes.shape({
        currency: PropTypes.string,
      }),
    }),
  }),
  renderAsHtml: PropTypes.bool,
};

AmountTemplate.defaultProps = {
  profile: {},
  renderAsHtml: false,
};

export default compose(
  connect(({ auth: { uuid, logged } }) => ({ logged, uuid })),
  withGeoLocation,
  graphql(profileCurrencyQuery, {
    options: ({ uuid }) => ({
      fetchPolicy: 'network-only',
      variables: {
        playerUUID: uuid,
      },
    }),
    skip: ({ logged }) => !logged,
    name: 'profile',
  })
)(AmountTemplate);
