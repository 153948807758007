import React from 'react';
import PropTypes from 'prop-types';

const SliderArrowPrev = ({ onClick }) => (
  <div className="slider-arrows slider-arrows--left">
    <button onClick={onClick} className="icon-angle-left slider-arrows__content" />
  </div>
);

SliderArrowPrev.propTypes = {
  onClick: PropTypes.func,
};
SliderArrowPrev.defaultProps = {
  onClick: null,
};

export default SliderArrowPrev;
