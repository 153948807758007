import React, { Component, Fragment } from 'react';
import { Switch, matchPath, Redirect } from 'react-router-dom';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import PropTypes from '../../../constants/prop-types';
import Home from '../../Home';
import NotFound from '../../NotFound';
import SignIn from '../../SignIn';
import ForgotPassword from '../../ForgotPassword';
import ResetPassword from '../../ResetPassword';
import SignUp from '../../SignUp';
import Games from '../../Games';
import Player from '../../Player';
import StaticPage from '../../StaticPage';
import AccountVerification from '../../AccountVerification';
import Game from '../../Game';
import { MainLayout } from '../../../layouts';
import { AppRoute, ModalRoute, ProtectedRoute, Route } from '../../../router';
import { availableStaticPageAliases } from '../../../constants/staticPage';
import { availableLanguages } from '../../../constants/languages';
import { getDomain } from '../../../config';
import { getUrlWithLang } from '../../../utils/url';
import Tournaments from '../../Tournaments';
import Lotteries from '../../Lotteries';
import PromoBonus from '../../PromoBonus';
import Promo from '../../Promo';
import getSearchParams from '../../../utils/getSearchParams';
import NotificationModals from './NotificationModals';
import CompleteProfile from '../../CompleteProfile';
import Responsible from '../../Responsible';
import Calendar from '../../Calendar';
import IgromatGame from '../../IgromatGame';

class IndexRoute extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.shape({
        modal: PropTypes.bool,
      }),
      query: PropTypes.object,
    }).isRequired,
    logged: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    domainData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      domainData: PropTypes.shape({
        title: PropTypes.string,
        metaDescription: PropTypes.string,
        redirect: PropTypes.string,
      }),
    }).isRequired,
    auth: PropTypes.shape({
      logged: PropTypes.bool,
      token: PropTypes.string,
      uuid: PropTypes.string,
    }).isRequired,
    modals: PropTypes.shape({
      postRegistrationModal: PropTypes.modalType.isRequired,
      errorModal: PropTypes.modalType.isRequired,
    }).isRequired,
  };

  static childContextTypes = {
    currency: PropTypes.object,
    auth: PropTypes.object,
    previousLocation: PropTypes.object,
    mainModals: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const {
      match: {
        params: { lang },
      },
    } = this.props;

    this.previousLocation = !this.isModalOpen()
      ? { ...this.props.location, query: { ...this.props.location.query, initialLocation: true } }
      : { pathname: `/${lang}` };
  }

  getChildContext() {
    const { auth, data, modals } = this.props;

    if (data.loading) {
      return {
        currency: {},
        auth,
        previousLocation: this.previousLocation,
        mainModals: modals,
      };
    }

    const {
      options: {
        signUp: { post },
      },
    } = data;
    const currency = get(post, 'currency');

    return {
      currency,
      mainModals: modals,
      auth,
      previousLocation: this.previousLocation,
    };
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    const replacePrevLocation = get(nextLocation, 'state.replacePrevLocation');

    if (nextProps.history.action !== 'POP') {
      if (replacePrevLocation) {
        this.previousLocation = replacePrevLocation;
      }

      if (!replacePrevLocation && !this.isModalOpen()) {
        this.previousLocation = location;
      }
    }
  }

  get modalRoutes() {
    const {
      match: { path },
      logged,
    } = this.props;
    const modals = [`${path}/player`, `${path}/block-test`];

    if (!logged) {
      return [...modals, `${path}/sign-in`, `${path}/sign-up`, `${path}/forgot-password`, `${path}/reset-password`];
    }

    return [...modals, `${path}/payment/success`];
  }

  isModal = () => {
    const { location } = this.props;

    return !!(location.state && location.state.modal && this.previousLocation !== location);
  };

  isModalOpen = () => {
    const { location } = this.props;
    const isMatchedModalRoutes = !!matchPath(location.pathname, {
      path: this.modalRoutes,
      exact: false,
    });

    return this.isModal() || isMatchedModalRoutes;
  };

  render() {
    const {
      location,
      match: {
        path,
        params: { lang },
      },
      logged,
      domainData: { domainData },
    } = this.props;
    const isModal = this.isModalOpen();
    const isNotFound = get(location, 'query.isNotFound');
    const { redirectUrl, ...search } = getSearchParams(location.search);
    const { title, metaDescription } = get(domainData, 'data') || {};

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          <For each="language" of={availableLanguages}>
            <link
              rel="alternate"
              hrefLang={language}
              key={language}
              href={`${window.location.protocol}//${getDomain()}${getUrlWithLang(location.pathname, language)}`}
            />
          </For>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={metaDescription} />
        </Helmet>
        <If condition={(!location.state || !location.state.ignoreRedirectUrl) && redirectUrl}>
          <Redirect to={{ pathname: redirectUrl, query: search }} />
        </If>
        <NotificationModals location={location} />
        <Choose>
          <When condition={!isNotFound}>
            <Switch location={isModal ? this.previousLocation : location}>
              <AppRoute layout={MainLayout} path={`${path}/`} exact component={Home} />
              <Route disableTrackAffiliate component={AccountVerification} path={`${path}/account-verification`} />
              <Route path={`${path}/game/:gameProvider/:gameAlias/:mode(demo)`} component={Game} />
              <ProtectedRoute path={`${path}/game/:gameProvider/:gameAlias/:mode(real)`} component={Game} />
              <AppRoute
                layout={MainLayout}
                path={`${path}/games`}
                component={Games}
                layoutProps={{ image: '/img/banners/games.jpg' }}
              />
              <AppRoute layout={MainLayout} path={`${path}/tournaments`} component={Tournaments} />
              <AppRoute layout={MainLayout} path={`${path}/lotteries`} component={Lotteries} />
              <AppRoute
                layout={MainLayout}
                path={`${path}/promo`}
                exact
                component={Promo}
                layoutProps={{ image: '/img/banners/promos.jpg' }}
              />
              <AppRoute layout={MainLayout} path={`${path}/promo/:alias`} component={PromoBonus} />
              <AppRoute layout={MainLayout} path={`${path}/responsible`} component={Responsible} />
              <AppRoute
                layout={MainLayout}
                path={`${path}/calendar`}
                component={Calendar}
                layoutProps={{ image: '/img/banners/calendar.png' }}
              />
              <AppRoute
                layout={MainLayout}
                path={`${path}/:alias(${availableStaticPageAliases.join('|')})`}
                component={StaticPage}
              />
              <ProtectedRoute path={`${path}/profile-form`} component={CompleteProfile} />
              <ProtectedRoute path={`${path}/area51/game/:internalGameId`} component={IgromatGame} />
              <Route component={NotFound} />
            </Switch>
          </When>
          <Otherwise>
            <Route component={NotFound} />
          </Otherwise>
        </Choose>
        <Switch>
          <Choose>
            <When condition={!logged}>
              <ModalRoute path={`${path}/sign-up`} component={SignUp} />
              <ModalRoute path={`${path}/sign-in`} component={SignIn} />
              <ModalRoute path={`${path}/forgot-password`} component={ForgotPassword} />
              <ModalRoute path={`${path}/reset-password`} component={ResetPassword} />
            </When>
            <Otherwise>
              <Redirect from={`${path}/(sign-up|sign-in|forgot-password|reset-password)`} to={`/${lang}`} />
            </Otherwise>
          </Choose>
          <ProtectedRoute modal path={`${path}/player`} component={Player} />
        </Switch>
      </Fragment>
    );
  }
}

export default IndexRoute;
