import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SignUpFormContainer from '../../../SignUp/container/SignUpFormContainer';
import ActiveBonusProgressBar from '../../../../components/ActiveBonusProgressBar';
import { Link, SocialIcons, Button, Icon } from '../../../../components';
import withLocalization from '../../../../utils/withLocalization';
import './LeftSidebar.scss';

const LeftSidebar = ({ logged, isFavorite, playerProfile, bonus, t, handleClickAddFavorite, onBackToLobbyClick }) => (
  <div className="d-none d-md-block col-xl-2 left-sidebar">
    <Choose>
      <When condition={logged}>
        <If condition={playerProfile}>
          <ul className="left-sidebar-logged">
            <li className="text-truncate left-sidebar-logged__player">
              <Icon icon="user" className="left-sidebar-logged__icon" />
              <Link to={{ pathname: '/player/profile', state: { modal: true } }}>{playerProfile.username}</Link>
            </li>
            <If condition={bonus}>
              <li className="left-sidebar-logged__bonus-wrapper">
                <If condition={bonus.image}>
                  <img className="img-fluid left-sidebar-logged__bonus-img" src={bonus.image} alt="bonus" />
                </If>
                <Choose>
                  <When condition={bonus.freeSpinStatus}>
                    <div className="left-sidebar-logged__free-spin">
                      {bonus.gameData && bonus.gameData.fullGameName}
                      {t('t.bonus.freeSpin.count', {
                        playedCount: bonus.playedCount,
                        freeSpinsAmount: bonus.freeSpinsAmount,
                      })}
                    </div>
                  </When>
                  <Otherwise>
                    <ActiveBonusProgressBar
                      id="game-left-sidebar-bonus-progress"
                      className="left-sidebar-logged__bonus"
                      bonus={bonus}
                    />
                  </Otherwise>
                </Choose>
              </li>
            </If>
            <li className="my-xl-3 left-sidebar-logged__actions">
              <Button
                className="left-sidebar-logged__deposit-btn"
                tag={Link}
                to={{ pathname: '/player/cashbox', state: { modal: true } }}
              >
                {t('t.make.n.deposit')}
              </Button>
              <Button
                className="left-sidebar-logged__presents-btn"
                outline
                tag={Link}
                to={{ pathname: '/player/bonuses', state: { modal: true } }}
              >
                {t('t.gifts')}
              </Button>
            </li>
            <li className="d-none d-xl-block">
              <button
                type="button"
                className={classNames('game__add-to-fav', { active: isFavorite })}
                onClick={handleClickAddFavorite}
              >
                <Icon icon="favorite" className="game__add-to-fav-icon" />
                <span className="game__add-to-fav-text">
                  <Choose>
                    <When condition={isFavorite}>{t('t.favorites.remove')}</When>
                    <Otherwise>{t('t.favorites.add')}</Otherwise>
                  </Choose>
                </span>
              </button>
            </li>
          </ul>
        </If>
      </When>
      <Otherwise>
        <div className="d-none d-xl-block">
          <div className="left-sidebar__title">{t('t.register')}</div>
          <SignUpFormContainer />
        </div>
        <ul className="row no-gutters left-sidebar__guest-tablet-row d-xl-none">
          <li className="col-auto">
            <Button
              className="left-sidebar__sign-up-btn"
              tag={Link}
              to={{ pathname: '/sign-up', state: { modal: true } }}
            >
              {t('t.register')}
            </Button>
            <Button
              className="left-sidebar__sign-in-btn"
              outline
              tag={Link}
              to={{ pathname: '/sign-in', state: { modal: true } }}
            >
              {t('t.login')}
            </Button>
          </li>
          <li className="col-auto mt-3 mt-md-0">
            <SocialIcons label={t('t.login.via.social')} />
          </li>
        </ul>
      </Otherwise>
    </Choose>
    <button type="button" className="game__back-btn d-none d-xl-inline-block mt-2" onClick={onBackToLobbyClick}>
      <Icon icon="arrow-left" className="button-back__icon" />
      {t('t.btn.backToGames')}
    </button>
  </div>
);

LeftSidebar.propTypes = {
  t: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
  playerProfile: PropTypes.shape({
    username: PropTypes.string,
  }),
  bonus: PropTypes.shape({
    campaignUUID: PropTypes.string,
    expirationDate: PropTypes.string,
    endDate: PropTypes.string,
    optIn: PropTypes.bool,
    state: PropTypes.string,
    playerStatus: PropTypes.string,
    title: PropTypes.string,
    gameData: PropTypes.shape({
      internalGameId: PropTypes.string,
      fullGameName: PropTypes.string,
    }),
    playedCount: PropTypes.number,
    freeSpinsAmount: PropTypes.number,
    freeSpinStatus: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  isFavorite: PropTypes.bool,
  handleClickAddFavorite: PropTypes.func,
  onBackToLobbyClick: PropTypes.func.isRequired,
};

LeftSidebar.defaultProps = {
  playerProfile: null,
  bonus: null,
  isFavorite: false,
  handleClickAddFavorite: null,
};

export default withLocalization(LeftSidebar);
