import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ShortPreloader.scss';

const ShortPreloader = ({ type }) => (
  <div
    className={classNames(
      'short-preloader',
      { custom: type === 'custom' },
      { 'loading-link': type === 'link' },
      { 'loading-btn': type === 'button' }
    )}
  >
    <div className="short-preloader__wrapper">
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
    </div>
  </div>
);

ShortPreloader.propTypes = {
  type: PropTypes.oneOf(['custom', 'link', 'button']),
};
ShortPreloader.defaultProps = {
  type: null,
};

export default ShortPreloader;
