const sliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  swipe: false,
  initialSlide: 1,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 10000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        initialSlide: 3,
      },
    },
  ],
};

const sliderSettingsVertical = {
  slidesToShow: 5,
  slidesToScroll: 1,
  swipe: false,
  initialSlide: 1,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 10000,
  pauseOnHover: false,
  vertical: true,
};

export { sliderSettings, sliderSettingsVertical };
