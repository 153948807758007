import countries from 'country-list';

const countryList = countries.getCodeList();

const config = {
  apiUrl: '',
  graphqlUrl: '',
  affiliateUrl: '',
  cmsUrl: '',
  webSocketUrl: '',
  brandId: '',
  defaultLanguage: 'en',
  currency: 'EUR',
  middlewares: {
    unauthorized: [401],
    persist: { whitelist: ['affiliate', 'auth', 'promo'] },
  },
  domain: '',
  ...window.appConfig,
};

config.middlewares.persist.keyPrefix = `${config.brandId}:`;

function getDomain() {
  return config.domain || window.location.hostname;
}

function getAffiliateUrl() {
  return config.affiliateUrl;
}

function getApiUrl() {
  return config.getApiUrl;
}

function getSocialAuthUrl() {
  return config.socialAuthUrl || `/api/social_auth`;
}

function getWebSocketUrl() {
  return `${window.location.origin.replace(/^http?/, 'ws')}/socket`;
}

function getBrandId() {
  return config.brandId;
}

function getCountryName(code) {
  return countryList[code.toLowerCase()];
}

function getBaseCurrency() {
  return config.currency;
}

export {
  getSocialAuthUrl,
  getWebSocketUrl,
  getBrandId,
  getCountryName,
  getAffiliateUrl,
  countryList,
  getDomain,
  getBaseCurrency,
  getApiUrl,
};

export default config;
