import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import PropTypes from '../../constants/prop-types';
import Amount from '../Amount';
import Link from '../Link';
import ErrorModal from '../ErrorModal';
import withLocalization from '../../utils/withLocalization';
import withModals from '../../utils/withModals';
import './WinnerItem.scss';

class WinnerItem extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    winAmount: PropTypes.money.isRequired,
    gameNg: PropTypes.shape({
      fullGameName: PropTypes.string.isRequired,
      image: PropTypes.string,
      slug: PropTypes.string.isRequired,
      providerId: PropTypes.string.isRequired,
      restricted: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
    styles: PropTypes.object,
    logged: PropTypes.bool.isRequired,
    modals: PropTypes.shape({
      error: PropTypes.shape({
        show: PropTypes.func,
      }),
    }).isRequired,
  };
  static defaultProps = {
    className: null,
    styles: null,
  };

  handleClick = (e) => {
    const {
      game: { restricted },
      modals: { error },
    } = this.props;

    if (restricted) {
      e.preventDefault();
      error.show({ title: 't.game.restricted.title', content: 't.game.restricted.desc' });
    }
  };

  render() {
    const {
      username,
      winAmount,
      gameNg: { fullGameName, slug, providerId, image },
      t,
      className,
      styles,
      logged,
    } = this.props;

    const itemLink = logged ? `/game/${providerId}/${slug}/real` : { pathname: '/sign-in', state: { modal: true } };

    return (
      <div className={classNames('winner-item', className)} style={styles}>
        <div className="winner-item__image" style={{ backgroundImage: `url(${image})` }} />
        <div className="winner-item__content text-truncate">
          {username}
          <br />
          {t('t.winners.won')} <Amount integer className="winner-item__amount" {...winAmount} />
          <br />
          {t('t.winners.in.game')}{' '}
          <Link className="winner-item__game-name" onClick={this.handleClick} to={itemLink}>
            {fullGameName}
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withModals({
    error: ErrorModal,
  }),
  withLocalization
)(WinnerItem);
