import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import { parse } from 'qs';

class AuthToken extends Component {
  static propTypes = {
    authorizeUser: PropTypes.func.isRequired,
    useToken: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  state = {
    redirect: false,
  };

  async componentWillMount() {
    const {
      location: { search },
      useToken: tokenResponse,
      authorizeUser,
    } = this.props;
    const { token, landing } = parse(search, { ignoreQueryPrefix: true });

    if (token) {
      const useTokenResponse = await tokenResponse({ variables: { token } });
      const data = get(useTokenResponse, 'data.player.useToken.data');

      if (data) {
        await authorizeUser({ ...data });
      }
    }

    this.setState({ redirect: true, showPostRegModal: landing === '1' });
  }

  render() {
    if (!this.state.redirect) {
      return null;
    }

    return <Redirect to={{ pathname: '/', query: { showPostRegModal: this.state.showPostRegModal } }} />;
  }
}

export default AuthToken;
