const responsiveSettings = [
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 3,
      arrows: false,
      centerMode: true,
    },
  },
  {
    breakpoint: 430,
    settings: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '120px',
    },
  },
  {
    breakpoint: 375,
    settings: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '100px',
    },
  },
  {
    breakpoint: 340,
    settings: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '80px',
    },
  },
];

export default responsiveSettings;
