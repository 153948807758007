import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { removeFromFavoritesMutation, addToFavoritesMutation } from '../../../apollo/queries/game';
import Game from '../components/Game';
import withLocalization from '../../../utils/withLocalization';
import withGeoLocation from '../../../utils/withGeoLocation';
import getDeviceType from '../../../utils/getDeviceType';
import withModals from '../../../utils/withModals';
import ExitToLobbyModal from '../components/ExitToLobbyModal';

const query = gql`
  query GameBase($alias: String!, $playerUUID: String, $locale: String, $country: String) {
    gameNg(slug: $alias, countryCode: $country) {
      _id
      fullGameName
      description
      isFavorite
      internalGameId
      providerId
      disabledOnActiveBonus
    }
    gameLottery(locale: $locale) {
      data {
        id
        title
        startDate
        prizePool {
          amount
          currency
        }
        shortDescription
        status
        endDate
        imageSm
        prizes {
          title
          image
          amount
          price {
            amount
            currency
          }
        }
      }
      error {
        error
      }
    }
    mainTournament(playerUUID: $playerUUID, locale: $locale) {
      data {
        title
        alias
        id
        icon
        prizePool {
          amount
          currency
        }
        playerPlace {
          username
          rank
          amount
          prizeFund {
            amount
            currency
          }
        }
        leaderboard(size: 4) {
          username
          rank
          amount
          prizeFund {
            amount
            currency
          }
        }
        status
        startDate
        endDate
      }
      error {
        error
      }
    }
  }
`;

const activeBonusQuery = gql`
  query activeBonus($playerUUID: String!, $deviceType: String, $locale: String, $internalGameId: String) {
    activeBonus(playerUUID: $playerUUID, locale: $locale) {
      campaignUUID
      expirationDate
      endDate
      leftToWage {
        amount
        currency
      }
      optIn
      state
      playerStatus
      amountToWage {
        amount
        currency
      }
      bonusType
      progress
      title
      shortDescription
      description
      image
    }
    bonusCampaigns(
      playerUUID: $playerUUID
      deviceType: $deviceType
      locale: $locale
      freeSpinStatus: ACTIVE
      internalGameId: $internalGameId
    ) {
      data {
        expirationDate
        gameData {
          fullGameName
          internalGameId
        }
        campaignUUID
        playedCount
        freeSpinsAmount
        alias
        minDeposit {
          amount
          currency
        }
        bonusType
        freeSpinStatus
        title
        shortDescription
        description
        image
      }
    }
  }
`;

const profileQuery = gql`
  query ProfileQuery($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      data {
        _id
        username
        totalBalance {
          amount
          currency
        }
        completed
      }
    }
  }
`;

export default compose(
  withGeoLocation,
  withRouter,
  connect(({ auth: { logged, uuid } }) => ({ logged, uuid })),
  graphql(query, {
    options: ({
      match: {
        params: { gameAlias, lang: locale },
      },
      uuid,
      geoLocation: { country },
    }) => ({
      fetchPolicy: 'network-only',
      variables: {
        alias: gameAlias,
        locale,
        playerUUID: uuid,
        country,
      },
    }),
  }),
  graphql(profileQuery, {
    options: ({
      uuid,
      match: {
        params: { lang: locale },
      },
    }) => ({
      variables: { playerUUID: uuid, locale },
    }),
    skip: ({ logged }) => !logged,
    props: (props) => ({
      ...props,
      data: {
        ...props.ownProps.data,
        player: get(props, 'player.player.data'),
        loading: get(props, 'ownProps.data.loading', props.player.loading || false),
      },
    }),
    name: 'player',
  }),
  graphql(activeBonusQuery, {
    options: ({
      uuid,
      match: {
        params: { lang: locale, gameId },
      },
    }) => ({
      pollInterval: 10000,
      variables: {
        deviceType: getDeviceType(),
        playerUUID: uuid,
        locale,
        internalGameId: gameId,
      },
    }),
    skip: ({ logged, data: { gameByAlias } }) => !logged || !get(gameByAlias, 'data.internalGameId'),
    name: 'activeBonus',
  }),
  graphql(addToFavoritesMutation, {
    props: ({ ownProps, addToFavorites }) => ({
      addToFavorites({ internalGameId }) {
        return addToFavorites({
          variables: { internalGameId, playerUUID: ownProps.uuid },
          optimisticResponse: {
            game: {
              __typename: 'GameMutation',
              addToFavorites: {
                __typename: 'Game',
                _id: internalGameId,
                isFavorite: true,
              },
            },
          },
        });
      },
    }),
    name: 'addToFavorites',
  }),
  graphql(removeFromFavoritesMutation, {
    props: ({ ownProps, removeFromFavorites }) => ({
      removeFromFavorites({ internalGameId }) {
        return removeFromFavorites({
          variables: { internalGameId, playerUUID: ownProps.uuid },
          optimisticResponse: {
            game: {
              __typename: 'GameMutation',
              removeFromFavorites: {
                __typename: 'Game',
                _id: internalGameId,
                isFavorite: false,
              },
            },
          },
        });
      },
    }),
    name: 'removeFromFavorites',
  }),
  withModals({
    exitModal: ExitToLobbyModal,
  }),
  withLocalization
)(Game);
