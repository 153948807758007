import React from 'react';
import PropTypes from 'prop-types';
import withLocalization from '../../utils/withLocalization';
import { navigation } from '../../constants/menu';
import NavigationItem from '../NavigationItem';
import './Navigation.scss';

const Navigation = ({ t }) => (
  <nav className="navigation">
    {navigation.map(({ key, label, url }) => (
      <NavigationItem key={key} to={url}>
        {t(label)}
      </NavigationItem>
    ))}
  </nav>
);

Navigation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withLocalization(Navigation);
