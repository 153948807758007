import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import Winners from './Winners';
import { getBrandId } from '../../config';
import withGeoLocation from '../../utils/withGeoLocation';

const winnersQuery = gql`
  query Winners($brandId: String!, $country: String, $currency: String!) {
    winners(brandId: $brandId, country: $country, currency: $currency) {
      uuid
      username
      gameNg {
        fullGameName
        slug
        providerId
        image
        restricted
      }
      winAmount {
        amount
        currency
      }
      date
    }
  }
`;

export default compose(
  connect(({ auth: { logged } }) => ({ logged })),
  withGeoLocation,
  graphql(winnersQuery, {
    options: ({ geoLocation: { country, currency } }) => ({
      variables: {
        brandId: getBrandId(),
        country,
        currency,
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 300000,
    }),
  })
)(Winners);
