import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import SignUpForm from '../components/SignUpForm';
import { getBrandId } from '../../../config';
import { actionCreators as affiliateActionCreators } from '../../../redux/modules/affiliate';
import { actionCreators as authActionCreators } from '../../../redux/modules/auth';
import withLocalization from '../../../utils/withLocalization';
import { signUpQuery as optionsQuery } from '../../../apollo/queries/options';
import withGeoLocation from '../../../utils/withGeoLocation';

const mapActionCreators = {
  signIn: authActionCreators.signIn,
  cmsSignIn: authActionCreators.cmsSignIn,
  clearAffiliate: affiliateActionCreators.clear,
};

const mutation = gql`
  mutation createPlayer(
    $email: String!
    $password: String!
    $languageCode: String!
    $currency: String!
    $terms: Boolean!
    $affiliateId: String
    $identifier: String
    $btag: String
  ) {
    player {
      create(
        email: $email
        password: $password
        languageCode: $languageCode
        currency: $currency
        terms: $terms
        affiliateId: $affiliateId
        identifier: $identifier
        btag: $btag
      ) {
        error {
          error
          fields_errors
        }
        data {
          playerUUID
        }
      }
    }
  }
`;

export default compose(
  withRouter,
  withLocalization,
  withGeoLocation,
  graphql(mutation, { name: 'createPlayer' }),
  graphql(optionsQuery, {
    options: () => ({
      variables: {
        brandId: getBrandId(),
      },
    }),
    props: (props) => ({
      ...props,
      initialValues: {
        currency: get(props, 'ownProps.geoLocation.currency'),
        terms: true,
      },
      data: {
        ...props.data,
        options: props.data.options || {
          signUp: {
            post: {},
          },
        },
      },
    }),
  }),
  connect(({ affiliate }) => ({ affiliate }), mapActionCreators)
)(SignUpForm);
