import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { matchPath, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from '../../../constants/prop-types';
import { NotFoundRedirect } from '../../../router';
import history from '../../../router/history';
import Logo from '../../../components/Logo';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Preloader from '../../../components/Preloader';
import GameView from '../../../components/GameView';
import { Icon } from '../../../components';
import isMobile from '../../../utils/isMobile';
import './Game.scss';

class Game extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    addToFavorites: PropTypes.func.isRequired,
    removeFromFavorites: PropTypes.func.isRequired,
    activeBonus: PropTypes.shape({
      activeBonus: PropTypes.shape({
        campaignUUID: PropTypes.string,
        expirationDate: PropTypes.string,
        endDate: PropTypes.string,
        optIn: PropTypes.bool,
        state: PropTypes.string.isRequired,
        playerStatus: PropTypes.string,
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
      }),
    }),
    data: PropTypes.shape({
      player: PropTypes.shape({
        username: PropTypes.string.isRequired,
        totalBalance: PropTypes.money.isRequired,
        completed: PropTypes.bool,
      }),
      mainTournament: PropTypes.shape({
        data: PropTypes.shape({
          title: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
          prizePool: PropTypes.money.isRequired,
          alias: PropTypes.string.isRequired,
          leaderboard: PropTypes.arrayOf(
            PropTypes.shape({
              rank: PropTypes.number.isRequired,
              username: PropTypes.string,
              amount: PropTypes.string,
              prizeFund: PropTypes.money.isRequired,
            })
          ).isRequired,
          status: PropTypes.string.isRequired,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
      }),
      gameLottery: PropTypes.shape({
        data: PropTypes.shape({
          title: PropTypes.string.isRequired,
          imageSm: PropTypes.string,
          prizePool: PropTypes.money.isRequired,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          id: PropTypes.string.isRequired,
          prizes: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              amount: PropTypes.number.isRequired,
              image: PropTypes.string,
            })
          ),
          status: PropTypes.string.isRequired,
        }),
      }),
      gameNg: PropTypes.shape({
        description: PropTypes.string,
        disabledOnActiveBonus: PropTypes.bool,
        internalGameId: PropTypes.string.isRequired,
        fullGameName: PropTypes.string.isRequired,
        providerId: PropTypes.string.isRequired,
        isFavorite: PropTypes.bool,
      }),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        gameAlias: PropTypes.string.isRequired,
        gameProvider: PropTypes.string.isRequired,
        lang: PropTypes.string.isRequired,
        mode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    logged: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    modals: PropTypes.shape({
      exitModal: PropTypes.shape({
        show: PropTypes.func,
      }),
    }).isRequired,
  };
  static defaultProps = {
    activeBonus: {},
  };
  static contextTypes = {
    previousLocation: PropTypes.object,
  };

  componentWillReceiveProps({ data: { gameNg: nextGame, loading }, activeBonus: nextActiveBonus }) {
    const {
      match: {
        params: { mode, lang },
      },
    } = this.props;

    if (mode === 'real' && !loading) {
      if (!nextActiveBonus.loading) {
        const disabledOnActiveBonus = get(nextGame, 'disabledOnActiveBonus', false);

        if (disabledOnActiveBonus && get(nextActiveBonus, 'activeBonus')) {
          history.replace({ pathname: `/${lang}/games/all`, query: { bonusDisabledGameModal: true } });
        }
      }
    }
  }

  componentWillUpdate({ logged: nextLogged, player, data }) {
    const playerData = get(data, 'player');

    if (nextLogged && !playerData && !player.loading) {
      player.startPolling(500);
      this.startPlayerPolling = true;
    }

    if (this.startPlayerPolling && nextLogged && playerData) {
      this.startPlayerPolling = false;
      player.startPolling();
    }
  }

  get backToLobbyUrl() {
    const {
      match: {
        params: { lang },
      },
    } = this.props;
    const { previousLocation } = this.context;
    const match = matchPath(previousLocation.pathname, { path: '/games/:category' });

    return match ? `/${lang}/games/${match.params.category}` : `/${lang}/games/all`;
  }

  exitModalShown = false;

  createLeaveFromPageHandler = (callback) => () => {
    const {
      modals: { exitModal },
      data: { gameNg },
    } = this.props;
    const internalGameId = get(gameNg, 'internalGameId', '');

    if (!this.exitModalShown) {
      exitModal.show({ excludeInternalGameId: internalGameId });
      this.exitModalShown = true;
    } else if (typeof callback === 'function') {
      callback();
    }
  };

  handleLogoClick = this.createLeaveFromPageHandler(() => {
    const {
      match: {
        params: { lang },
      },
    } = this.props;

    history.push({ pathname: `/${lang}` });
  });

  handleBackToLobbyClick = this.createLeaveFromPageHandler(() => {
    history.push({ pathname: this.backToLobbyUrl });
  });

  handleClickAddFavorite = () => {
    const {
      data: {
        gameNg: { internalGameId, isFavorite },
      },
      addToFavorites,
      removeFromFavorites,
    } = this.props;

    if (isFavorite) {
      removeFromFavorites({ internalGameId });
    } else {
      addToFavorites({ internalGameId });
    }
  };

  render() {
    const {
      activeBonus,
      data: { player, mainTournament, gameLottery, loading, gameNg: game },
      match: {
        params: { lang, mode },
      },
      logged,
      location: { pathname },
      t,
    } = this.props;

    if (loading) {
      return <Preloader />;
    }

    if (!game && !loading) {
      return <NotFoundRedirect />;
    }

    const playerProfileCompleted = get(player, 'completed', false);

    if (mode === 'real' && !playerProfileCompleted) {
      return <Redirect to={{ pathname: `/${lang}/profile-form`, state: { returnUrl: pathname } }} />;
    }

    if (isMobile.any()) {
      return <GameView gameId={game.internalGameId} mode={mode} gameProviderId={game.gameProviderId} />;
    }

    const tournament = get(mainTournament, 'data');
    const bonus = get(activeBonus, 'activeBonus') || get(activeBonus, 'bonusCampaigns.data[0]');
    const lottery = get(gameLottery, 'data');

    return (
      <Fragment>
        <If condition={game && game.fullGameName}>
          <Helmet titleTemplate="">
            <title>{t('t.game.title', { gameName: game.fullGameName })}</title>
          </Helmet>
        </If>

        <div className="game">
          <Logo className="game__logo" onClick={this.handleLogoClick} />
          <div className="row no-gutters">
            <LeftSidebar
              logged={logged}
              playerProfile={player}
              bonus={bonus}
              isFavorite={game.isFavorite}
              internalGameId={game.internalGameId}
              handleClickAddFavorite={this.handleClickAddFavorite}
              onBackToLobbyClick={this.handleBackToLobbyClick}
            />
            <div className="col-xl px-xl-3 my-md-4 my-xl-0">
              <GameView
                gameId={game.internalGameId}
                mode={mode}
                onExitClick={this.handleBackToLobbyClick}
                gameProviderId={game.gameProviderId}
              />
            </div>
            <RightSidebar tournament={tournament} lottery={lottery} />
          </div>
          <div className="d-none d-md-flex d-xl-none game__actions">
            <button
              type="button"
              className="game__back-btn d-none d-xl-inline-block mt-2"
              onClick={this.handleBackToLobbyClick}
            >
              <Icon icon="arrow-left" className="button-back__icon" />
              {t('t.btn.backToGames')}
            </button>
            <If condition={logged}>
              <button
                type="button"
                className={classNames('game__add-to-fav', { active: game.isFavorite })}
                onClick={this.handleClickAddFavorite}
              >
                <Icon icon="favorite" className="game__add-to-fav-icon" />
                <span className="game__add-to-fav-text">
                  <Choose>
                    <When condition={game.isFavorite}>{t('t.favorites.remove')}</When>
                    <Otherwise>{t('t.favorites.add')}</Otherwise>
                  </Choose>
                </span>
              </button>
            </If>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Game;
