import React from 'react';
import PropTypes from 'prop-types';
import withLocalization from '../../utils/withLocalization';
import InfoModal from '../InfoModal';
import SuccessBlock from '../SuccessBlock';

const SuccessModal = ({ title, content, handleCloseModal, isOpen, t, zIndex }) => (
  <InfoModal
    title={t(title)}
    onClose={handleCloseModal}
    onButtonClick={handleCloseModal}
    isOpen={isOpen}
    zIndex={zIndex}
  >
    <SuccessBlock className="col-sm-8 col-md-6 mx-sm-auto" text={t(content)} />
  </InfoModal>
);

SuccessModal.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
};

SuccessModal.defaultProps = {
  zIndex: 1052,
};

export default withLocalization(SuccessModal);
