const paymentMethods = [
  'card',
  'qiwi',
  'yamoney',
  'payeer',
  'beeline',
  'tele2',
  'megafon',
  'skrill',
  'neteller',
  'mts',
  'perfectmoney',
  'sofort',
  'piastrix',
  'b2crypto',
  'mir',
  'ecopayz',
  'b2binpay',
  'rapidtransfer',
  'paysafecard',
  'nordea',
  'cap_bank',
  'qr',
  'community_bank',
  'paygiga',
  'easyeft',
  'astropay',
  'zimpler',
];
const gameProviders = ['playson', 'amaticdirect', 'betsoft', 'endorphina', 'hab', 'qs', 'microgaming', 'netent'];

export { paymentMethods, gameProviders };
