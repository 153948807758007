import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from '../../constants/prop-types';
import SlickSlider from '../SlickSlider';
import WinnerItem from './WinnerItem';
import { sliderSettings } from './constants';
import './WinnersHorizontal.scss';

class WinnersHorizontal extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        winAmount: PropTypes.money.isRequired,
        gameNg: PropTypes.shape({
          fullGameName: PropTypes.string.isRequired,
          image: PropTypes.string,
          slug: PropTypes.string.isRequired,
          providerId: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    logged: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return this.slider && !isEqual(nextProps.items, this.props.items);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.next();
    }
  }

  next() {
    if (this.slider) {
      this.slider.slickNext();
    }
  }

  render() {
    const { items, onCloseClick, logged } = this.props;

    return (
      <div className="d-none d-md-block winners-horizontal">
        <div className="container winners-horizontal__container">
          <SlickSlider innerRef={(node) => (this.slider = node)} {...sliderSettings}>
            {items.map((item, index) => (
              <div key={[`${item.username}-${index}`]}>
                <WinnerItem {...item} className="winners-horizontal__item" logged={logged} />
              </div>
            ))}
          </SlickSlider>
          <button type="button" className="icon-close winners-horizontal__close-btn" onClick={onCloseClick} />
        </div>
      </div>
    );
  }
}

export default WinnersHorizontal;
