import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import Calendar from '../components/Calendar';
import { STATUSES } from '../../../constants/statuses';
import { lotteriesQuery } from '../../../apollo/queries/lotteries';
import { tournamentsQuery } from '../../../apollo/queries/tournaments';
import { calendarLeaderboardQuery } from '../../../apollo/queries/calendarLeaderboard';
import withLocalization from '../../../utils/withLocalization';

export default compose(
  connect(({ auth: { uuid }, i18nState: { lang: locale } }) => ({ uuid, locale })),
  graphql(lotteriesQuery, {
    options: ({ locale }) => ({
      variables: {
        locale,
        status: [STATUSES.ACTIVE, STATUSES.PENDING, STATUSES.FINISHED],
      },
    }),
    name: 'lotteries',
  }),
  graphql(tournamentsQuery, {
    options: ({ locale }) => ({
      variables: {
        locale,
        status: [STATUSES.ACTIVE, STATUSES.PENDING, STATUSES.FINISHED],
      },
    }),
    name: 'tournaments',
  }),
  graphql(calendarLeaderboardQuery, {
    options: ({ locale }) => ({
      variables: {
        locale,
      },
    }),
    name: 'leaderboard',
  }),
  withLocalization
)(Calendar);
