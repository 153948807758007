import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import 'moment-duration-format';
import withLocalization from '../../utils/withLocalization';
import './Timer.scss';

class Timer extends PureComponent {
  static propTypes = {
    delay: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    format: PropTypes.string,
    onTimeout: PropTypes.func,
    showTitle: PropTypes.bool,
    className: PropTypes.string,
    showLabel: PropTypes.bool,
    hideOnEnd: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
  };
  static defaultProps = {
    format: 'dd  hh  mm  ss',
    onTimeout: () => {},
    showTitle: true,
    className: null,
    hideOnEnd: false,
    showLabel: true,
    label: 't.timer.endTime',
    labelClassName: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextDelay = Math.max(Math.floor(nextProps.delay / 1000), 0);

    if (prevState.originalDelay !== nextDelay) {
      return {
        originalDelay: nextDelay,
        delay: nextDelay,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      delay: Math.max(Math.floor(props.delay / 1000), 0),
    };
  }

  componentDidMount() {
    if (this.state.delay > 0) {
      this.intervalId = setInterval(this.handleTick, 1000);
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  intervalId = null;

  handleTick = () => {
    const { onTimeout } = this.props;

    const nextDelay = this.state.delay - 1;

    this.setState({ delay: nextDelay });

    if (nextDelay <= 0) {
      clearInterval(this.intervalId);
      onTimeout();
    }
  };

  render() {
    const { hideOnEnd, format, showTitle, className, showLabel, label, t, labelClassName } = this.props;
    const { delay } = this.state;

    if (hideOnEnd && delay <= 0) {
      return null;
    }

    return (
      <div className={classNames('timer', className)}>
        <If condition={showLabel}>
          <div className={classNames('timer__label', labelClassName)}>{t(label)}:</div>
        </If>
        <If condition={showTitle}>
          <div className="timer__title">
            <span>{t('t.timer.days')}</span>
            <span>{t('t.timer.hours')}</span>
            <span>{t('t.timer.minutes')}</span>
            <span>{t('t.timer.seconds')}</span>
          </div>
        </If>
        <div className="timer__content">
          <Choose>
            <When condition={delay <= 0}>{'00  00  00  00'}</When>
            <Otherwise>{moment.duration(delay, 'seconds').format(format, { trim: false })}</Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}

export default withLocalization(Timer);
