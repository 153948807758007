import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MainRoute from '../components/MainRoute';
import withGeoLocation from '../../../utils/withGeoLocation';

export default compose(
  withGeoLocation({
    showPreloader: true,
  }),
  withRouter
)(MainRoute);
