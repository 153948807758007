import React from 'react';
import { Button as ButtonStrap } from 'reactstrap';
import PropTypes from 'prop-types';
import buttonColors from '../../constants/buttonColors';
import './Button.scss';

const Button = ({ children, ...props }) => <ButtonStrap {...props}>{children}</ButtonStrap>;

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  color: PropTypes.oneOf([buttonColors.DEFAULT]),
  disabled: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  className: PropTypes.string,
  block: PropTypes.bool,
};

Button.defaultProps = {
  children: '',
  type: null,
  color: buttonColors.DEFAULT,
  disabled: false,
  tag: 'button',
  onClick: null,
  outline: false,
  className: null,
  block: false,
};

export default Button;
