import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider as ReactApolloProvider } from 'react-apollo';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import random from 'lodash/random';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { from } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import moment from 'moment';
import config, { getBaseCurrency, getBrandId } from '../config';
import history from '../router/history';
import { actionCreators as authActionCreators } from '../redux/modules/auth';
import { delay } from '../utils';

const offset = moment(config.serverTime).unix() - moment().unix();

class ApolloProvider extends PureComponent {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  constructor(props) {
    super(props);

    this.constructor.client = this.createClient();
  }

  state = {
    isError: false,
  };

  /**
   * @param {string} uuid
   * get player base data, and make polling if is not created yet
   */
  getBasePlayerData = (uuid) =>
    new Promise(async (resolve) => {
      const run = async (useCache = true) => {
        const response = await this.constructor.client.query({
          query: gql`
            query getBasePlayerData($playerUUID: String!) {
              player(playerUUID: $playerUUID) {
                error {
                  error
                }
                data {
                  _id
                  currency
                }
              }
            }
          `,
          variables: { playerUUID: uuid },
          fetchPolicy: useCache ? 'cache-only' : 'network-only',
          skip: !uuid,
        });

        const player = get(response, 'data.player');

        if (!player && !response.loading) {
          return resolve({});
        }

        if (player && player.data) {
          return resolve(player.data);
        }

        await delay(400);
        await run(false);
      };

      await run();
    });

  createClient() {
    const httpLink = new BatchHttpLink({ uri: '/api/graphql/gql', batchInterval: 50 });
    const errorLink = onError(({ graphQLErrors, response, networkError }) => {
      if (
        response &&
        response.errors &&
        response.errors.some((error) => error.extensions && error.extensions.code === 'UNAUTHENTICATED')
      ) {
        history.replace('/logout');
        response.errors = null;
      }

      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      this.setState({ isError: true });
    });

    const authLink = setContext(async ({ operationName }, { headers }) => {
      const {
        auth: { token, logged, uuid },
        i18nState: { lang },
      } = this.context.store.getState();
      const baseHeaders = {
        headers: {
          ...headers,
          'HRZN-Brand': getBrandId(),
          'HRZN-Locale': lang,
          'HRZN-Currency': getBaseCurrency(),
        },
      };

      if (!logged) {
        return baseHeaders;
      }

      if (token) {
        baseHeaders.headers.authorization = `Bearer ${token}`;
      }

      /**
       * refresh token
       */
      const tokenData = jwtDecode(token);

      if (tokenData.exp - (moment.utc().unix() + offset) < random(80, 100)) {
        if (!this.refreshedToken) {
          this.refreshedToken = this.context.store.dispatch(authActionCreators.refreshToken());
        }

        const refreshedToken = await this.refreshedToken;
        this.refreshedToken = null;

        if (
          !refreshedToken ||
          refreshedToken.error ||
          get(refreshedToken, 'payload.jwtError') ||
          get(refreshedToken, 'payload.response.jwtError')
        ) {
          history.replace('/logout');
        }

        const freshToken = get(refreshedToken, 'payload.jwtToken');

        if (freshToken) {
          baseHeaders.headers.authorization = `Bearer ${freshToken}`;
        }
      }

      /**
       * additional headers
       */
      if (operationName === 'getBasePlayerData') {
        return baseHeaders;
      }

      const playerBaseData = await this.getBasePlayerData(uuid);

      return {
        headers: {
          ...baseHeaders.headers,
          'HRZN-Currency': playerBaseData.currency,
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache({
        dataIdFromObject: (object) => {
          let typeName = object.__typename;

          if (typeName === 'GameNg') {
            typeName = 'Game';
          }

          return object._id ? `${typeName}:${object._id}` : null;
        },
      }),
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
  }

  render() {
    return <ReactApolloProvider client={this.constructor.client}>{this.props.children}</ReactApolloProvider>;
  }
}

export default ApolloProvider;
