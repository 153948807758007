import rusFlag from '../assets/img/flags/russian.svg';

const RU = 'ru';

const languages = {
  [RU]: {
    alias: 'russian',
    shortLabel: 'РУС',
    value: 'ru',
    flag: rusFlag,
  },
};

const defaultLanguage = RU;

const availableLanguages = [RU];

export { availableLanguages, defaultLanguage, languages };
