import secondSlideImage from '../../assets/img/post-registration-modal/second-slide-image.png';
import secondSlideBg from '../../assets/img/post-registration-modal/second-slide-bg.png';
import thirdSlideImage from '../../assets/img/post-registration-modal/third-slide-image.png';
import thirdSlideBg from '../../assets/img/post-registration-modal/third-slide-bg.png';

const content = [
  {
    slideTitle: 't.postreg_modal.second_slide.title',
    description: 't.postreg_modal.second_slide.description',
    slideImage: secondSlideImage,
    buttonLabel: 't.postreg_modal.second_slide.action',
    background: secondSlideBg,
  },
  {
    slideTitle: 't.postreg_modal.third_slide.title',
    description: 't.postreg_modal.third_slide.description',
    slideImage: thirdSlideImage,
    buttonLabel: 't.postreg_modal.third_slide.action',
    background: thirdSlideBg,
  },
];

export { content };
