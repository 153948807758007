import React from 'react';
import PropTypes from 'prop-types';
import { withLocalization } from '../../utils';
import { Button, Link } from '../../components';
import './NotFound.scss';

const NotFound = ({ t }) => (
  <div className="container">
    <div className="row not-found">
      <div className="col-md-6 not-found__number-text">404</div>
      <div className="col-md-6 not-found__content">
        <h1>{t('t.not_found.something_wrong.text')}</h1>
        <p className="not-found__text">{t('t.not_found.oh.text')}</p>
        <Button tag={Link} to="/">
          {t('t.btn.goBack')}
        </Button>
      </div>
    </div>
  </div>
);

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withLocalization(NotFound);
