import React from 'react';
import PropTypes from 'prop-types';
import withLocalization from '../../utils/withLocalization';
import { Modal, ModalHeader, ModalBody } from '../Modal';
import Button from '../Button';
import './InfoModal.scss';

const InfoModal = ({ title, buttonLabel, children, onClose, t, zIndex, onButtonClick, isOpen }) => (
  <Modal className="info-modal" isOpen={isOpen} backdrop="static" zIndex={zIndex} toggle={onClose}>
    <ModalHeader title={title} toggle={onClose} />
    <ModalBody>
      <div className="container-fluid">
        <div className="row">{children}</div>
        <div className="row">
          <div className="col-10 col-sm-7 col-md-5 mx-auto info-modal__actions">
            <Button className="info-modal__button" onClick={onButtonClick}>
              {buttonLabel || t('t.done')}
            </Button>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

InfoModal.propTypes = {
  title: PropTypes.PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  zIndex: PropTypes.number,
};
InfoModal.defaultProps = {
  buttonLabel: '',
  zIndex: 1050,
  onClose: null,
  isOpen: true,
};

export default withLocalization(InfoModal);
