import React from 'react';
import Waypoint from 'react-waypoint';
import PropTypes from '../../constants/prop-types';
import withLocalization from '../../utils/withLocalization';
import GameItem from '../GameItem';
import CustomRow from '../CustomRow';
import ShortPreloader from '../ShortPreloader';
import { Button } from '../../components';
import './GameGrid.scss';

const GameGrid = ({
  loadMoreGames,
  showMoreGamesButton,
  games,
  showWaypoint,
  logged,
  removeFromFavorites,
  addToFavorites,
  t,
  className,
  gameItemClassName,
  onGameButtonClick,
}) => (
  <CustomRow className={className}>
    {games.map((item) => (
      <GameItem
        key={item.internalGameId}
        logged={logged}
        addToFavorites={addToFavorites}
        removeFromFavorites={removeFromFavorites}
        className={gameItemClassName}
        onGameButtonClick={onGameButtonClick}
        {...item}
      />
    ))}
    <If condition={showMoreGamesButton}>
      <div className="col-12 game-grid__action">
        <Button type="button" className="game-grid__button" onClick={loadMoreGames}>
          {t('t.more.games')}
        </Button>
      </div>
    </If>
    <If condition={showWaypoint}>
      <Waypoint bottomOffset="30%" scrollableAncestor={window} onEnter={loadMoreGames}>
        <div className="col-12 game-grid__preloader">
          <ShortPreloader />
        </div>
      </Waypoint>
    </If>
  </CustomRow>
);

GameGrid.propTypes = {
  removeFromFavorites: PropTypes.func,
  addToFavorites: PropTypes.func,
  t: PropTypes.func.isRequired,
  loadMoreGames: PropTypes.func,
  games: PropTypes.arrayOf(PropTypes.game).isRequired,
  showWaypoint: PropTypes.bool,
  showMoreGamesButton: PropTypes.bool,
  logged: PropTypes.bool,
  className: PropTypes.string,
  gameItemClassName: PropTypes.string,
  onGameButtonClick: PropTypes.func,
};

GameGrid.defaultProps = {
  removeFromFavorites: null,
  addToFavorites: null,
  loadMoreGames: null,
  showMoreGamesButton: false,
  showWaypoint: false,
  logged: false,
  className: null,
  gameItemClassName: 'col-6 col-md-4 col-lg-3 col-xl-2',
  onGameButtonClick: null,
};

export default withLocalization(GameGrid);
