import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import CustomScrollBars from '../CustomScrollBars';
import './CustomSelect.scss';

class CustomSelect extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    value: PropTypes.string,
    optionComponent: PropTypes.any,
    optionClassName: PropTypes.string,
    noResultsText: PropTypes.string,
    hasDanger: PropTypes.bool,
    autoHeightMax: PropTypes.number,
  };
  static defaultProps = {
    name: null,
    disabled: false,
    placeholder: null,
    searchable: false,
    clearable: false,
    onChange: null,
    value: null,
    optionComponent: ReactSelect.defaultProps.optionComponent,
    optionClassName: null,
    noResultsText: 'Nothing found',
    hasDanger: false,
    autoHeightMax: 200,
  };

  renderSelectMenu = (params) => {
    const menu = ReactSelect.defaultProps.menuRenderer(params);

    return <CustomScrollBars autoHeightMax={this.props.autoHeightMax}>{menu}</CustomScrollBars>;
  };

  render() {
    const {
      searchable,
      name,
      options,
      disabled,
      placeholder,
      clearable,
      onChange,
      value,
      optionComponent,
      optionClassName,
      noResultsText,
      hasDanger,
    } = this.props;

    return (
      <ReactSelect
        name={name}
        className={classNames('custom-select-field', { 'has-danger': hasDanger })}
        options={options}
        onChange={onChange}
        disabled={disabled}
        inputProps={{
          autoComplete: 'off',
        }}
        value={value}
        searchable={searchable}
        clearable={clearable}
        menuRenderer={this.renderSelectMenu}
        placeholder={placeholder}
        optionComponent={optionComponent}
        optionClassName={optionClassName}
        removeSelected={false}
        noResultsText={noResultsText}
        onSelectResetsInput={false}
        autosize={false}
      />
    );
  }
}

export default CustomSelect;
