import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../CustomSelect';
import FormGroup from '../FormGroup';

class Select extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
    hideErrorMessage: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    searchable: PropTypes.bool,
    isRequired: PropTypes.bool,
    noResultsText: PropTypes.string,
    autoHeightMax: PropTypes.number,
    horizontal: PropTypes.bool,
  };
  static defaultProps = {
    disabled: false,
    hideErrorMessage: false,
    placeholder: null,
    onChange: null,
    className: null,
    searchable: false,
    label: null,
    labelClassName: null,
    meta: {},
    isRequired: false,
    noResultsText: 'Not found',
    autoHeightMax: 200,
    horizontal: false,
  };

  state = {
    selectedOption: { value: this.props.input.value },
  };

  handleChange = (selectedOption) => {
    const { onChange, input } = this.props;
    this.setState({ selectedOption });

    if (onChange) {
      return onChange(selectedOption);
    }

    return input.onChange(selectedOption.value);
  };

  render() {
    const {
      hideErrorMessage,
      input: { name },
      meta: { touched, error },
      options,
      disabled,
      className,
      placeholder,
      label,
      labelClassName,
      isRequired,
      searchable,
      noResultsText,
      autoHeightMax,
      horizontal,
    } = this.props;
    const { selectedOption } = this.state;
    const value = selectedOption && selectedOption.value;

    return (
      <FormGroup
        touched={touched}
        error={error}
        label={label}
        horizontal={horizontal}
        labelClassName={labelClassName}
        hideErrorMessage={hideErrorMessage}
        required={isRequired}
        className={className}
      >
        <CustomSelect
          name={name}
          options={options}
          onChange={this.handleChange}
          disabled={disabled}
          value={value}
          searchable={searchable}
          placeholder={placeholder}
          noResultsText={noResultsText}
          hasDanger={!!(touched && error)}
          autoHeightMax={autoHeightMax}
        />
      </FormGroup>
    );
  }
}

export default Select;
