import Validator from 'validatorjs';
import _ from 'lodash';
import moment from 'moment';

Validator.register(
  'nextDate',
  function (value, requirement) {
    return value >= this.validator.input[requirement];
  },
  'The :attribute must be equal or bigger'
);

Validator.register(
  'lessThan',
  function (inputValue, requirement, attribute) {
    const value = Number(inputValue);

    if (isNaN(value)) {
      this.validator.errors.add(attribute, 'Value must be a number');

      return false;
    }
    const greaterValue = Number(_.get(this.validator.input, requirement));

    if (greaterValue !== 0 && value >= greaterValue) {
      const targetAttributeLabel = this.validator.messages._getAttributeName(requirement);
      const currentAttributeLabel = this.validator.messages._getAttributeName(attribute);

      this.validator.errors.add(
        attribute,
        `The "${currentAttributeLabel}" must be less than "${targetAttributeLabel}"`
      );

      return false;
    }

    return true;
  },
  'The :attribute must be less'
);

Validator.register(
  'greaterThan',
  function (inputValue, requirement, attribute) {
    const value = Number(inputValue);

    if (isNaN(value)) {
      this.validator.errors.add(attribute, 'Value must be a number');

      return false;
    }
    const lessValue = Number(_.get(this.validator.input, requirement));

    if (lessValue !== 0 && value <= lessValue) {
      const targetAttributeLabel = this.validator.messages._getAttributeName(requirement);
      const currentAttributeLabel = this.validator.messages._getAttributeName(attribute);

      this.validator.errors.add(
        attribute,
        `The "${currentAttributeLabel}" must be greater than "${targetAttributeLabel}"`
      );

      return false;
    }

    return true;
  },
  'The :attribute must be greater'
);

Validator.register(
  'lessOrSame',
  function (inputValue, requirement, attribute) {
    const value = Number(inputValue);

    if (isNaN(value)) {
      this.validator.errors.add(attribute, 'Value must be a number');

      return false;
    }
    const greaterValue = Number(_.get(this.validator.input, requirement));

    return greaterValue === 0 || value <= greaterValue;
  },
  'The :attribute must be less'
);

Validator.register(
  'greaterOrSame',
  function (inputValue, requirement, attribute) {
    const value = Number(inputValue);

    if (isNaN(value)) {
      this.validator.errors.add(attribute, 'Value must be a number');

      return false;
    }
    const lessValue = Number(_.get(this.validator.input, requirement));

    return lessValue === 0 || value >= lessValue;
  },
  'The :attribute must be greater'
);

Validator.register(
  'customTypeValue.value',
  function (inputValue, requirement, attribute) {
    const attributeBaseName = attribute.replace(/\.value/, '');
    if (typeof this.validator.input[attributeBaseName]) {
      if (this.validator.input[attributeBaseName] === null) {
        return true;
      }

      const customTypeValueField = this.validator.input[attributeBaseName];

      if (!customTypeValueField.type) {
        this.validator.errors.add(`${attributeBaseName}.type`, 'Choose type of value');
      } else {
        const value = Number(inputValue);

        if (isNaN(value)) {
          this.validator.errors.add(attribute, 'Value must be a number');

          return false;
        }

        if (value < 0) {
          this.validator.errors.add(attribute, 'Value must be greater than 0');

          return false;
        }
      }

      return true;
    }

    return false;
  },
  'The :attribute must be a valid CustomType'
);

Validator.register(
  'allowedAgeRange',
  function (value, requirement, attribute) {
    if (!value) {
      this.validator.errors.add(attribute, 'error.validation.birthDate.validDate');

      return false;
    }

    const valueMoment = moment(value, 'DD.MM.YYYY');

    if (!valueMoment.isValid()) {
      this.validator.errors.add(attribute, 'error.validation.birthDate.validDate');

      return false;
    }

    const years = moment().diff(valueMoment, 'years');

    if (isNaN(years)) {
      this.validator.errors.add(attribute, 'error.validation.birthDate.validDate');

      return false;
    }

    if (years > 120) {
      this.validator.errors.add(attribute, 'error.validation.birthDate.maxAge');

      return false;
    }

    return years >= 18;
  },
  'error.validation.birthDate.age'
);

Validator.register(
  'expirationDate',
  function validateExpirationDate(value, requirement, attribute) {
    if (!value) {
      this.validator.errors.add(attribute, 'error.validation.expirationDate.validDate');

      return false;
    }

    const [month, year] = value.split('/').map((i) => parseInt(i, 10));

    if (Number.isNaN(year) || Number.isNaN(month) || month > 12) {
      this.validator.errors.add(attribute, 'error.validation.expirationDate.validDate');

      return false;
    }

    const currYear = parseInt(moment().format('YY'), 10);

    return (currYear === year && parseInt(moment().format('MM'), 10) <= month) || currYear < year;
  },
  'error.validation.expirationDate.lessThenToday'
);

Validator.register('required', (value) => !!value, 't.required.field');

Validator.register(
  'onlyLetters',
  (value) => value && /^\S+[a-zA-ZÀ-ž\sẞß]+$/.test(value),
  'error.validation.onlyLetters'
);
Validator.register('withoutSpaces', (value) => value && /^\S[\s\S]*\S$/.test(value), 'error.validation.startWithSpace');

const getFirstErrors = (errors) =>
  Object.keys(errors).reduce(
    (result, current) => ({
      ...result,
      [current]: errors[current][0],
    }),
    {}
  );

const createValidator = (rules, attributeLabels = {}, multipleErrors = false) => (data) => {
  const validation = new Validator(data, rules, {
    required: 't.required.field',
    same: 'error.validation.password.nonEquals',
    regex: 'error.invalid.format',
    email: 't.email.notvalid',
    min: 't.length.notvalid',
  });

  validation.setAttributeNames(attributeLabels);

  if (validation.fails()) {
    return multipleErrors ? validation.errors.all() : getFirstErrors(validation.errors.all());
  }

  return {};
};

export { createValidator };
export default createValidator;
