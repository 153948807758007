import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from '../Logo';
import './Preloader.scss';

const Preloader = ({ className }) => (
  <div className={classNames('preloader', className)}>
    <Logo />
    <div className="preloader__dots">
      <span className="preloader__dot" />
      <span className="preloader__dot" />
      <span className="preloader__dot" />
      <span className="preloader__dot" />
      <span className="preloader__dot" />
    </div>
  </div>
);

Preloader.propTypes = {
  className: PropTypes.string,
};

Preloader.defaultProps = {
  className: '',
};

export default Preloader;
