import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from '../../../../constants/prop-types';
import SlickSlider from '../../../../components/SlickSlider';
import Tournament from '../../../../components/Tournament';
import Lottery from '../../../../components/Lottery';
import Winners from '../../../../components/Winners';
import withLocalization from '../../../../utils/withLocalization';
import { sliderSettings } from './constants';
import './RightSidebar.scss';

class RightSidebar extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tournament: PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      prizePool: PropTypes.money.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      alias: PropTypes.string.isRequired,
      leaderboard: PropTypes.arrayOf(
        PropTypes.shape({
          rank: PropTypes.number.isRequired,
          username: PropTypes.string,
          amount: PropTypes.string,
          prizeFund: PropTypes.money.isRequired,
        })
      ).isRequired,
      status: PropTypes.string.isRequired,
    }),
    lottery: PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageSm: PropTypes.string,
      prizePool: PropTypes.money.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      prizes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          amount: PropTypes.number.isRequired,
        })
      ),
      status: PropTypes.string.isRequired,
    }),
  };
  static defaultProps = {
    tournament: null,
    lottery: null,
  };

  state = {
    activeSlide: 0,
  };

  handleChangeSlide = (slide) => {
    if (this.slider) {
      this.slider.slickGoTo(slide);
    }
  };

  render() {
    const { tournament, lottery, t } = this.props;
    const { activeSlide } = this.state;

    const arrayOfEvents = ['tournament', 'lottery'].filter((i) => this.props[i]);

    return (
      <div className="d-none d-xl-block col-xl-2 right-sidebar">
        <div className="right-sidebar__nav">
          <button
            type="button"
            className={classNames('icon-winners-cup right-sidebar__nav-item', { active: activeSlide === 0 })}
            onClick={() => this.handleChangeSlide(0)}
          />
          <For of={arrayOfEvents} each="item" index="index">
            <button
              key={item}
              type="button"
              className={classNames(`icon-${item}-cup right-sidebar__nav-item`, { active: activeSlide === index + 1 })}
              onClick={() => this.handleChangeSlide(index + 1)}
            />
          </For>
        </div>
        <SlickSlider
          {...sliderSettings}
          innerRef={(slider) => (this.slider = slider)}
          beforeChange={(current) => this.setState({ activeSlide: current })}
        >
          <div key="winners">
            <div className="right-sidebar__title">{t('t.game.right_sidebar.just_won')}</div>
            <Winners vertical />
          </div>
          <If condition={tournament}>
            <div key="tournament">
              <div className="right-sidebar__title">{t('t.game.right_sidebar.active_tournament')}</div>
              <Tournament
                {...tournament}
                image={tournament.icon}
                shortTable
                titleClassName="right-sidebar__event-title"
                actionClassName="right-sidebar__event-action"
                prizePoolClassName="right-sidebar__event-prize"
                subtitleClassName="right-sidebar__event-label"
                tableClassName="right-sidebar__event-table"
              />
            </div>
          </If>
          <If condition={lottery}>
            <div key="lottery">
              <div className="right-sidebar__title">{t('t.game.right_sidebar.active_lottery')}</div>
              <Lottery
                {...lottery}
                slider
                image={lottery.imageSm}
                titleClassName="right-sidebar__event-title"
                actionClassName="right-sidebar__event-action"
                prizePoolClassName="right-sidebar__event-prize"
                subtitleClassName="right-sidebar__event-label"
              />
            </div>
          </If>
        </SlickSlider>
      </div>
    );
  }
}

export default withLocalization(RightSidebar);
