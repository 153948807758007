import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '../Input';
import Select from '../Select';
import FormGroup from '../FormGroup';
import FieldError from '../FieldError';
import normalizeNumber from '../../../utils/normalizeNumber';

class Phone extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    phoneCodeList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ).isRequired,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    customError: PropTypes.string,
    isRequired: PropTypes.bool,
    completed: PropTypes.bool,
    addon: PropTypes.element,
    disabled: PropTypes.bool,
    phone: PropTypes.string,
    phoneCode: PropTypes.string,
    placeholder: PropTypes.string,
  };
  static defaultProps = {
    isRequired: false,
    label: null,
    labelClassName: null,
    phone: '',
    placeholder: null,
    phoneCode: '',
    customError: null,
    completed: false,
    addon: null,
    onChange: null,
    disabled: false,
  };

  state = {
    phone: this.props.phone,
    phoneCode: this.props.phoneCode,
  };

  componentWillReceiveProps({ phone: nextPhone, phoneCode: nextPhoneCode }) {
    if (this.props.phone !== nextPhone) {
      this.setState({ phone: nextPhone });
    }

    if (this.props.phoneCode !== nextPhoneCode) {
      this.setState({ phoneCode: nextPhoneCode });
    }
  }

  handleChangePhoneCode = (_, phoneCode) => {
    const { phone } = this.state;

    this.setState({ phoneCode, phone });
    this.props.onChange({ phoneCode, phone });
  };

  handleChangePhone = (e) => {
    const phone = e.target.value;
    const { phoneCode } = this.state;

    this.setState({ phoneCode, phone });
    this.props.onChange({ phone, phoneCode });
  };

  render() {
    const {
      phoneCodeList,
      addon,
      completed,
      label,
      labelClassName,
      customError,
      isRequired,
      placeholder,
      name,
      disabled,
    } = this.props;

    return (
      <FormGroup label={label} labelClassName={labelClassName} required={isRequired}>
        <div className="form-row">
          <Field
            component={Select}
            name={`${name}Code`}
            onChange={this.handleChangePhoneCode}
            searchable
            disabled={disabled}
            options={phoneCodeList}
            hideErrorMessage
            className="col-4 mb-0"
            autoHeightMax={140}
          />
          <Field
            type="text"
            addon={addon}
            name={name}
            placeholder={placeholder}
            autoComplete="off"
            completed={completed}
            component={Input}
            disabled={disabled}
            onChange={this.handleChangePhone}
            parse={normalizeNumber}
            maxLength={12}
            hideErrorMessage
            hideIcons
            className="col-8 mb-0"
          />
          <If condition={customError}>
            <FieldError error={customError} className="col-12" />
          </If>
        </div>
      </FormGroup>
    );
  }
}

export default Phone;
