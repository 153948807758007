import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import './CustomScrollBars.scss';

class CustomScrollBars extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    autoHeight: PropTypes.bool,
    autoHeightMax: PropTypes.number,
    thumbMinSize: PropTypes.number,
  };
  static defaultProps = {
    autoHeight: true,
    autoHeightMax: 440,
    thumbMinSize: 30,
  };

  renderTrackVertical = (props) => <div {...props} className="track-vertical" />;
  renderThumbVertical = (props) => <div {...props} className="thumb-vertical" />;
  renderThumbHorizontal = (props) => <div {...props} className="track-horizontal" />;

  render() {
    const { children, autoHeight, autoHeightMax, thumbMinSize } = this.props;

    return (
      <Scrollbars
        autoHeight={autoHeight}
        autoHeightMax={autoHeightMax}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackHorizontal={this.renderThumbHorizontal}
        thumbMinSize={thumbMinSize}
        hideTracksWhenNotNeeded
      >
        {children}
      </Scrollbars>
    );
  }
}

export default CustomScrollBars;
