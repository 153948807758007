import React from 'react';
import PropTypes from '../../constants/prop-types';
import SlickSlider from '../SlickSlider';
import WinnerItem from './WinnerItem';
import { sliderSettingsVertical } from './constants';
import './WinnersVertical.scss';

const WinnersVertical = ({ items, logged }) => (
  <div className="winners-vertical">
    <SlickSlider {...sliderSettingsVertical}>
      {items.map((item, index) => (
        <div key={[`${item.username}-${index}`]}>
          <WinnerItem {...item} className="winners-vertical__item" logged={logged} />
        </div>
      ))}
    </SlickSlider>
  </div>
);

WinnersVertical.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      winAmount: PropTypes.money.isRequired,
      gameNg: PropTypes.shape({
        fullGameName: PropTypes.string.isRequired,
        image: PropTypes.string,
        slug: PropTypes.string.isRequired,
        providerId: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  logged: PropTypes.bool.isRequired,
};

export default WinnersVertical;
