import React, { Component } from 'react';
import { Navbar, Collapse } from 'reactstrap';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from '../../../constants/prop-types';
import Logo from '../../Logo';
import MobileMenu from '../MobileMenu';
import { Link, Button, Icon } from '../../../components';
import ActiveBonusProgressBar from '../../ActiveBonusProgressBar';
import { renderGameCategoriesArray } from '../../../utils';
import LoadingLink from '../../LoadingLink';
import Toggler from '../Toggler';
import './Header.scss';

class Header extends Component {
  static propTypes = {
    logged: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    bonusCampaigns: PropTypes.shape({
      loading: PropTypes.bool,
      refetch: PropTypes.func,
      bonusCampaigns: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            uuid: PropTypes.string,
          })
        ),
      }),
    }),
    profile: PropTypes.shape({
      loading: PropTypes.bool,
      bonuses: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            bonusType: PropTypes.string,
            progress: PropTypes.number,
            leftToWage: PropTypes.money,
            amountToWage: PropTypes.money,
          })
        ),
      }),
      player: PropTypes.shape({
        data: PropTypes.shape({
          username: PropTypes.string,
          totalBalance: PropTypes.money,
        }),
      }),
    }),
    gameCategories: PropTypes.gameCategories.isRequired,
  };
  static defaultProps = {
    bonusCampaigns: {},
    profile: {
      player: {
        data: {
          totalBalance: {},
        },
      },
    },
  };

  state = {
    isOpen: false,
  };

  get categories() {
    const { gameCategories, logged } = this.props;

    const categories = get(gameCategories, 'gameNgCategories.value', []).filter((category) => category !== 'recent');

    if (logged) {
      categories.push('favorite');
    }

    return renderGameCategoriesArray(categories);
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      profile,
      logged,
      t,
      bonusCampaigns: { loading: bonusCampaignsLoading, bonusCampaigns },
      gameCategories: { loading: categoriesLoading },
    } = this.props;
    const { isOpen } = this.state;

    if (profile.error) {
      return null;
    }

    const { bonuses, player } = profile;
    const username = get(player, 'data.username', '...');
    const { amount, currency } = get(player, 'data.totalBalance', { amount: 0, currency: 'RUB' });
    const bonus = get(bonuses, 'data[0]', null);
    const availableBonuses = get(bonusCampaigns, 'data');

    return (
      <Navbar id="app-header" className="header" tag="header" expand="md">
        <div className="container">
          <Toggler clicked={isOpen} onClick={this.toggleMenu} />
          <Logo className="header__logo" to="/" />
          <Choose>
            <When condition={logged}>
              <div className="d-none d-md-flex logged-header">
                <Link
                  className="d-none d-lg-inline-block logged-header__username"
                  to={{ pathname: '/player/profile', state: { modal: true } }}
                >
                  <Icon icon="user" className="logged-header__icon" />
                  <span className="d-none d-xl-inline-block">{t('t.profile')}</span>
                  <span className="logged-header__props">{username}</span>
                </Link>
                <Link
                  className="d-none d-lg-inline-block logged-header__balance"
                  to={{ pathname: '/player/cashbox', state: { modal: true } }}
                >
                  <Icon icon={`currency-${currency.toLowerCase()}`} className="logged-header__icon" />
                  <span className="d-none d-xl-inline-block">{t('t.balance')}</span>
                  <span className="logged-header__props">{amount}</span>
                </Link>
                <If condition={bonus}>
                  <ActiveBonusProgressBar
                    id="bonus"
                    bonus={bonus}
                    className="d-none d-lg-inline-block logged-header__active-bonus"
                  />
                </If>
                <Button
                  className="logged-header__cashbox"
                  to={{ pathname: '/player/cashbox', state: { modal: true } }}
                  tag={Link}
                >
                  {t('t.cashier')}
                </Button>
                <Button
                  loading={bonusCampaignsLoading}
                  className="logged-header__presents"
                  tag={LoadingLink}
                  outline
                  to={{ pathname: '/player/bonuses', state: { modal: true } }}
                >
                  {t('t.gifts')}
                  <If condition={availableBonuses && availableBonuses.length > 0 && !profile.loading}>
                    <div className="badge badge--animated">
                      <Icon icon="bell" className="badge__label" />
                    </div>
                  </If>
                </Button>
                <RouterLink
                  to="/logout"
                  className={classNames('logged-header__logout', { 'is-disabled': profile.loading })}
                >
                  <Icon icon="logout" className="logged-header__icon" />
                  {t('t.logout')}
                </RouterLink>
              </div>
            </When>
            <Otherwise>
              <div className="guest-header">
                <Button
                  className="guest-header__sign-up"
                  to={{ pathname: '/sign-up', state: { modal: true } }}
                  tag={Link}
                >
                  {t('t.register')}
                </Button>
                <Button
                  className="guest-header__sign-in"
                  to={{ pathname: '/sign-in', state: { modal: true } }}
                  tag={Link}
                  outline
                >
                  {t('t.login')}
                </Button>
              </div>
            </Otherwise>
          </Choose>
        </div>
        <If condition={logged}>
          <div className="row no-gutters d-lg-none logged-header-actions">
            <div className="col logged-header-actions__item">
              <Link className="d-inline-block" to={{ pathname: '/player/profile', state: { modal: true } }}>
                <Icon icon="user" className="logged-header__icon" />
                <span className="d-none d-md-inline-block">{t('t.profile')}</span>
                <span className="logged-header__props">{username}</span>
              </Link>
            </div>
            <If condition={bonus}>
              <div className="d-none d-sm-block col logged-header-actions__item">
                <div className="logged-header-actions__active-bonus">
                  <Icon icon="gift" className="mr-2 logged-header__icon" />
                  <ActiveBonusProgressBar id="bonus" bonus={bonus} />
                </div>
              </div>
            </If>
            <div className="d-none d-sm-block col logged-header-actions__item">
              <Link className="d-inline-block" to={{ pathname: '/player/cashbox', state: { modal: true } }}>
                <Icon icon={`currency-${currency.toLowerCase()}`} className="logged-header__icon" />
                <span className="d-none d-md-inline-block">{t('t.balance')}</span>
                <span className="logged-header__props">{amount}</span>
              </Link>
            </div>
            <div className="d-sm-none col logged-header-actions__item">
              <Link className="d-inline-block" to={{ pathname: '/player/bonuses', state: { modal: true } }}>
                <span className="logged-header-actions__presents">
                  <Icon icon="gift" className="logged-header__icon" />
                  <If condition={availableBonuses && availableBonuses.length > 0}>
                    <div className="badge badge--animated">
                      <Icon icon="bell" className="badge__label" />
                    </div>
                  </If>
                </span>
                <span className="logged-header__props">{t('t.gifts')}</span>
              </Link>
            </div>
            <div className="d-sm-none col-12 logged-header-actions__cashbox">
              <div className="logged-header-actions__cashbox-wrapper">
                <div className="logged-header__balance logged-header-actions__cashbox-balance">
                  {t('t.balance')}
                  <span className="logged-header__props">{amount}</span>
                </div>
                <Button
                  className="logged-header-actions__cashbox-btn"
                  to={{ pathname: '/player/cashbox', state: { modal: true } }}
                  tag={Link}
                >
                  {t('t.cashier')}
                </Button>
              </div>
            </div>
          </div>
        </If>
        <Collapse isOpen={isOpen} navbar>
          <div className="container">
            <If condition={!categoriesLoading}>
              <MobileMenu bonus={bonus} handleCloseMenu={this.toggleMenu} categories={this.categories} />
            </If>
          </div>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
