import delay from './delay';

const defaultValidateFn = (action) => !!(action && !action.error);

const retryFunction = async (fn, opts) => {
  const options = {
    retries: 5,
    timeout: 500,
    pow: 2,
    validateFn: defaultValidateFn,
    ...opts,
  };

  let retry = 0;
  let result;

  do {
    if (retry !== 0) {
      await delay(retry * options.pow * options.timeout || options.timeout);
    }

    result = await fn();

    if (options.validateFn(result)) {
      return result;
    }

    retry += 1;
  } while (retry !== options.retries);

  return result;
};

export default retryFunction;
