import PropTypes from 'prop-types';

PropTypes.modalType = PropTypes.shape({
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
});
PropTypes.money = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});
PropTypes.pageable = (content) =>
  PropTypes.shape({
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    numberOfElements: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        ascending: PropTypes.bool.isRequired,
        direction: PropTypes.string.isRequired,
        ignoreCase: PropTypes.bool.isRequired,
        nullHandling: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
      })
    ),
    totalElements: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    content: PropTypes.arrayOf(content).isRequired,
  });
PropTypes.pageableState = (content) =>
  PropTypes.shape({
    entities: PropTypes.pageable(content).isRequired,
    isLoading: PropTypes.bool.isRequired,
    receivedAt: PropTypes.number,
    error: PropTypes.object,
  });
PropTypes.game = PropTypes.shape({
  slug: PropTypes.string,
  aggregatorId: PropTypes.string,
  fullGameName: PropTypes.string.isRequired,
  gameId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  lines: PropTypes.any,
  startGameUrl: PropTypes.string,
  stopGameUrl: PropTypes.string,
});
PropTypes.staticPage = PropTypes.shape({
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});
PropTypes.lotteriesEntity = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageSm: PropTypes.string,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    prizes: PropTypes.arrayOf(
      PropTypes.shape({
        prise: PropTypes.money,
        amount: PropTypes.number,
        title: PropTypes.string,
      })
    ),
    prizePool: PropTypes.money.isRequired,
  })
);
PropTypes.tournamentsEntity = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    prizePool: PropTypes.money.isRequired,
    status: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    places: PropTypes.arrayOf(PropTypes.money),
  })
);
PropTypes.calendarLeaderboardEntity = PropTypes.arrayOf(
  PropTypes.shape({
    rank: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
    score: PropTypes.string.isRequired,
    prize: PropTypes.string,
    playerUUID: PropTypes.string.isRequired,
  })
);
PropTypes.gameCategories = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
  gameNgCategories: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
});

export default PropTypes;
