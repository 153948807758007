import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import withLocalization from '../../utils/withLocalization';
import PropTypes from '../../constants/prop-types';
import Timer from '../Timer';
import LeaderboardTable from '../LeaderboardTable';
import EventItem from '../EventItem';
import { STATUSES } from '../../constants/statuses';

const Tournament = ({
  title,
  image,
  prizePool,
  leaderboard,
  startDate,
  endDate,
  playerPlace,
  t,
  id,
  icon,
  shortTable,
  status,
  className,
  titleClassName,
  actionClassName,
  prizePoolClassName,
  subtitleClassName,
  tableClassName,
}) => {
  const delay = moment
    .duration(moment.utc(startDate && moment.utc(startDate).local().isAfter() ? startDate : endDate).diff())
    .asMilliseconds();

  return (
    <EventItem
      title={title}
      icon={icon}
      className={className}
      titleClassName={titleClassName}
      image={image}
      prizePool={prizePool}
      link={`/tournaments/${id}`}
      actionClassName={actionClassName}
      prizePoolClassName={prizePoolClassName}
    >
      <div className={classNames('event-item__subtitle', subtitleClassName)}>{t('t.tournament.leaders')}:</div>
      <LeaderboardTable
        playerPlace={playerPlace}
        leaderboard={leaderboard}
        shortTable={shortTable}
        className={tableClassName}
      />
      <Timer
        className="event-item__timer"
        label={status === STATUSES.PENDING ? 't.timer.startTime' : 't.timer.endTime'}
        delay={delay}
      />
    </EventItem>
  );
};

Tournament.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  prizePool: PropTypes.money.isRequired,
  t: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  leaderboard: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number.isRequired,
      username: PropTypes.string,
      amount: PropTypes.string,
      prizeFund: PropTypes.money.isRequired,
    })
  ),
  playerPlace: PropTypes.shape({
    rank: PropTypes.number.isRequired,
    username: PropTypes.string,
    amount: PropTypes.string,
    prizeFund: PropTypes.money,
  }),
  icon: PropTypes.string,
  shortTable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  actionClassName: PropTypes.string,
  prizePoolClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  tableClassName: PropTypes.string,
};

Tournament.defaultProps = {
  icon: null,
  playerPlace: null,
  shortTable: false,
  className: null,
  titleClassName: null,
  actionClassName: null,
  prizePoolClassName: null,
  subtitleClassName: null,
  tableClassName: null,
  image: '',
  leaderboard: [],
};

export default withLocalization(Tournament);
