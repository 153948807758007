import React from 'react';
import Link from '../Link';
import { paymentMethods, gameProviders } from './constants';
import Sprite from '../Sprite';
import FooterNav from './FooterNav';
import './Footer.scss';

const Footer = () => (
  <footer id="app-footer" className="footer">
    <FooterNav />
    <div className="footer__sprites">
      {paymentMethods.map((paymentMethod) => (
        <Link
          key={paymentMethod}
          to={{ pathname: '/player/cashbox', state: { paymentMethod } }}
          className="footer__sprite"
        >
          <Sprite item={paymentMethod} />
        </Link>
      ))}
    </div>
    <div className="footer__sprites my-3">
      {gameProviders.map((gameProvider) => (
        <Link key={gameProvider} to={`/games/all/${gameProvider}`} className="footer__sprite">
          <Sprite item={gameProvider} />
        </Link>
      ))}
    </div>
    <div className="footer__sprites">
      <Sprite item="18plus" className="mr-4" />
      <Sprite item="curacao" />
    </div>
  </footer>
);

export default Footer;
