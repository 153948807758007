import React from 'react';
import ReactDOM from 'react-dom';
import createStore from './store/createStore';
import App from './App';

if (window && typeof window.location.origin === 'undefined') {
  window.location.origin = `${window.location.protocol}//${window.location.host}`;
}

createStore({}, (store) => {
  ReactDOM.render(<App reduxStore={store} />, document.getElementById('root'));
});
