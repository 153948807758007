import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import SocialIcons from './SocialIcons';
import { getBrandId } from '../../config';
import { withGeoLocation } from '../../utils';

const socialProvidersQuery = gql`
  query SocialIcons($brandId: String!) {
    options {
      socialProviders(brandId: $brandId) {
        alias
        name
      }
    }
  }
`;

const socialAccountsQuery = gql`
  query SocialAccounts($playerUUID: String!) {
    socialAccounts(playerUUID: $playerUUID) {
      data {
        id
        provider
      }
    }
  }
`;

export default compose(
  connect(({ auth: { logged, uuid }, affiliate }) => ({ logged, uuid, affiliate })),
  graphql(socialProvidersQuery, {
    name: 'socialProviders',
    options: () => ({
      variables: {
        brandId: getBrandId(),
      },
    }),
  }),
  graphql(socialAccountsQuery, {
    options: ({ logged, uuid }) => ({
      skip: !logged,
      variables: {
        playerUUID: uuid,
      },
    }),
    name: 'socialAccounts',
  }),
  withRouter,
  withGeoLocation
)(SocialIcons);
