import React from 'react';
import PropTypes from '../../../../constants/prop-types';
import get from 'lodash/get';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modal';
import GameGrid from '../../../../components/GameGrid';
import { Button, Link, ShortPreloader } from '../../../../components';
import './ExitToLobbyModal.scss';

const ExitToLobbyModal = ({
  isOpen,
  handleCloseModal,
  games: { loading, gamesNg: list },
  t,
  excludeInternalGameId,
  logged,
}) => {
  const games = get(list, 'content', [])
    .filter((i) => i.internalGameId !== excludeInternalGameId)
    .slice(0, 3);

  return (
    <Modal className="exit-to-lobby" isOpen={isOpen} toggle={handleCloseModal}>
      <ModalHeader title={t('t.exit_to_lobby_modal.title')} toggle={handleCloseModal} />
      <ModalBody className="pt-0 text-center">
        <div className="exit-to-lobby__content">
          <div className="exit-to-lobby__header">{t('t.exit_to_lobby_modal.header')}</div>
          <Button type="button" className="exit-to-lobby__continue" onClick={handleCloseModal}>
            {t('t.exit_to_lobby_modal.back_to_game')}
          </Button>
          <Link className="exit-to-lobby__exit" to="/">
            {t('t.exit_to_lobby_modal.exit')}
          </Link>
        </div>
        <div className="exit-to-lobby__games">
          <Choose>
            <When condition={loading}>
              <ShortPreloader type="custom" />
            </When>
            <Otherwise>
              <div className="exit-to-lobby__games-title">{t('t.exit_to_lobby_modal.games.title')}</div>
              <GameGrid
                games={games}
                className="exit-to-lobby__games-grid"
                gameItemClassName="col-md-4 exit-to-lobby__game"
                onGameButtonClick={handleCloseModal}
                logged={logged}
              />
            </Otherwise>
          </Choose>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExitToLobbyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  games: PropTypes.shape({
    loading: PropTypes.bool,
    gamesNg: PropTypes.shape({
      page: PropTypes.number.isRequired,
      last: PropTypes.bool.isRequired,
      content: PropTypes.arrayOf(PropTypes.game).isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  excludeInternalGameId: PropTypes.string,
  logged: PropTypes.bool.isRequired,
};

ExitToLobbyModal.defaultProps = {
  excludeInternalGameId: null,
};

export default ExitToLobbyModal;
