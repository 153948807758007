import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'redux-i18n';
import get from 'lodash/get';
import { Switch, Redirect, matchPath } from 'react-router-dom';
import { getUrlWithLang } from '../../../utils/url';
import IndexRoute from '../../../routes';
import { availableLanguages, defaultLanguage } from '../../../constants/languages';
import { Localization, Route } from '../../../router';
import Logout from '../../../routes/Logout';
import AuthToken from '../../../routes/AuthToken';

class MainRoute extends Component {
  static propTypes = {
    geoLocation: PropTypes.shape({
      language: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  get lang() {
    const {
      geoLocation: { language },
      location: { pathname },
    } = this.props;

    const fallbackLanguage = availableLanguages.indexOf(language) !== -1 ? language : defaultLanguage;

    return (
      get(matchPath(pathname, { path: `/:lang(${availableLanguages.join('|')})` }), 'params.lang') || fallbackLanguage
    );
  }

  render() {
    const {
      location: { pathname, search, query },
    } = this.props;
    const { lang } = this;

    return (
      <I18n translations={{}} useReducer>
        <Switch>
          <Route disableTrackAffiliate component={Logout} path="/logout" />
          <Route disableTrackAffiliate component={AuthToken} path="/auth-token" />
          <Route
            path={`/:lang(${availableLanguages.join('|')})`}
            disableTrackAffiliate
            render={(props) => (
              <Localization {...props}>
                <IndexRoute {...props} />
              </Localization>
            )}
          />
          <Redirect to={{ query, pathname: getUrlWithLang(pathname, lang), search }} />
        </Switch>
      </I18n>
    );
  }
}

export default MainRoute;
