import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { getUrlWithLang } from '../../utils/url';
import history from '../../router/history';

class Link extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    to: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    staticContext: PropTypes.any,
  };
  static defaultProps = {
    staticContext: null,
  };

  get to() {
    const {
      to,
      match: {
        params: { lang },
      },
    } = this.props;
    let linkTo = {};

    if (typeof to === 'object') {
      linkTo = { ...to };
    } else {
      linkTo.pathname = to;
    }

    const absolute = this.isAbsolute(linkTo.pathname);
    const { location } = history;

    linkTo.state = {
      ...linkTo.state,
      prevLocation: {
        ...location,
        state: {
          ...location.state,
          prevLocation: null,
        },
      },
      absolute,
    };
    linkTo.pathname = absolute ? linkTo.pathname : getUrlWithLang(linkTo.pathname, lang);

    return linkTo;
  }

  isAbsolute = (url) => url.match(/^https?:\/\//) !== null;

  render() {
    const { match, location, staticContext, history, to, ...props } = this.props;

    const link = this.to;

    return (
      <Choose>
        <When condition={!link.state.absolute}>
          <RouterLink {...props} to={link} />
        </When>
        <Otherwise>
          <a {...props} href={link.pathname} rel="noopener" />
        </Otherwise>
      </Choose>
    );
  }
}

export default withRouter(Link);
