import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isMobile from '../../../utils/isMobile';
import FormGroup from '../FormGroup';
import Icon from '../../Icon';
import './CheckBox.scss';

const CheckBox = ({ input, label, disabled, meta: { touched, error }, additionalLabel }) => (
  <FormGroup touched={touched} error={error}>
    <label className={classNames('checkbox', { 'has-danger': touched && error }, { touch: isMobile.any() })}>
      {label}
      <input {...input} disabled={disabled} type="checkbox" />
      <Icon icon="checked" className="checkbox__icon" />
    </label>
    <If condition={additionalLabel}>{additionalLabel}</If>
  </FormGroup>
);

CheckBox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  additionalLabel: PropTypes.node,
};

CheckBox.defaultProps = {
  input: null,
  disabled: false,
  additionalLabel: null,
};

export default CheckBox;
