import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import Link from '../Link';
import { getBrandId, getSocialAuthUrl } from '../../config';
import buildQueryString from '../../utils/buildQueryString';
import './SocialIcons.scss';

const SocialIcons = (props, { auth: { token } }) => {
  const {
    className,
    label,
    labelClassName,
    layout,
    socialAccounts,
    socialProviders,
    geoLocation: { currency },
    affiliate,
    match: {
      params: { lang },
    },
  } = props;
  const accounts = get(socialAccounts, 'socialAccounts.data', []);
  const providers = get(socialProviders, 'options.socialProviders', []);

  return (
    <If condition={providers.length}>
      <nav className={classNames('media-icons', { vertical: layout === 'vertical' }, className)}>
        <If condition={label}>
          <span className={classNames('media-icons__label', labelClassName)}>{label}</span>
        </If>
        {providers.map((provider) => (
          <Link
            key={provider.name}
            className={classNames(`media-icons__link icon-auth-${provider.name.slice(0, 2)}`, {
              active: accounts.findIndex(({ provider: accountProvider }) => accountProvider === provider.name) !== -1,
            })}
            to={SocialIcons.getRedirectUrl(provider.alias, token, { ...affiliate, lang, currency })}
          >
            <span className="d-none">{provider.name}</span>
          </Link>
        ))}
      </nav>
    </If>
  );
};

SocialIcons.contextTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
  }),
};

SocialIcons.getRedirectUrl = function getRedirectUrl(alias, token = null, extra = {}) {
  let baseUrl = `${getSocialAuthUrl()}/public${token ? '/connect' : ''}/${alias}`;
  const queryString = {
    successUrl: `${window.location.origin}/sign-in`,
    failureUrl: `${window.location.origin}/error`,
    ...extra,
  };

  if (!token) {
    baseUrl = `${baseUrl}/${getBrandId()}`;
  } else {
    queryString.token = token;
    queryString.successUrl = `${window.location.origin}/player/profile`;
    queryString.failureUrl = `${window.location.origin}/player/profile`;
  }

  return `${baseUrl}?${buildQueryString(queryString)}`;
};

SocialIcons.propTypes = {
  socialProviders: PropTypes.shape({
    loading: PropTypes.bool,
    options: PropTypes.shape({
      socialProviders: PropTypes.arrayOf(
        PropTypes.shape({
          alias: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  socialAccounts: PropTypes.shape({
    loading: PropTypes.bool,
    socialAccounts: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          provider: PropTypes.string,
        })
      ),
    }),
  }),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  affiliate: PropTypes.shape({
    affiliateId: PropTypes.string,
    btag: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lang: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  geoLocation: PropTypes.shape({
    currency: PropTypes.string,
  }).isRequired,
};
SocialIcons.defaultProps = {
  label: null,
  layout: 'horizontal',
  socialAccounts: {
    loading: false,
    socialAccounts: {
      data: [],
    },
  },
  className: null,
  labelClassName: null,
};

export default SocialIcons;
