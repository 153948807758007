import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import get from 'lodash/get';
import { Route as DomRoute, matchPath, withRouter } from 'react-router-dom';
import { getAffiliateUrl, getBrandId } from '../../config';
import GET_AFFILIATE_ID from '../../apollo/queries/affiliateId';

class Route extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    disableTrackAffiliate: PropTypes.bool,
    disableScroll: PropTypes.bool,
    logged: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    referrerUserId: PropTypes.number,
    history: PropTypes.object.isRequired,
    uuid: PropTypes.string,
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        to: PropTypes.string,
        state: PropTypes.object,
        query: PropTypes.object,
        search: PropTypes.string,
      }),
    ]),
  };
  static defaultProps = {
    path: undefined,
    referrerUserId: null,
    disableScroll: false,
    disableTrackAffiliate: false,
    uuid: '',
  };
  static contextTypes = {
    changeLayoutProps: PropTypes.func,
  };

  static trackAffiliate(uuid) {
    const affUrl = getAffiliateUrl();

    if (affUrl) {
      this.affTimeout = setTimeout(
        () =>
          fetch(`${affUrl}/page-view/${uuid}?brandId=${getBrandId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        2000
      );
    }
  }

  static trackGuestAffiliate(referrerUserId) {
    const affUrl = getAffiliateUrl();

    if (affUrl) {
      this.affTimeout = setTimeout(
        () =>
          fetch(`${affUrl}/guest-page-view/${referrerUserId}?brandId=${getBrandId()}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        2000
      );
    }
  }

  async componentDidMount() {
    const {
      disableScroll,
      disableTrackAffiliate,
      location,
      path,
      logged,
      referrerUserId,
      history,
      uuid,
      layoutProps,
    } = this.props;
    const { changeLayoutProps } = this.context;

    if (matchPath(location.pathname, { path, exact: true })) {
      const isModal = location.state && location.state.modal;
      const isActionPop = (location.state && location.state.action === 'POP') || history.action === 'POP';

      if (!disableScroll && !isModal && !isActionPop) {
        window.scrollTo(0, 0);
      }

      if (!disableTrackAffiliate) {
        const affiliateId = await this.getAffiliateId();

        if (affiliateId) {
          Route.trackAffiliate(uuid);
        } else if (referrerUserId && !logged) {
          Route.trackGuestAffiliate(referrerUserId);
        }
      }

      if (layoutProps) {
        changeLayoutProps(layoutProps);
      }
    }
  }

  async componentWillUpdate({ location: nextLocation, path, history, layoutProps: nextLayoutProps }) {
    const {
      disableScroll,
      uuid,
      disableTrackAffiliate,
      referrerUserId,
      logged,
      layoutProps,
      location: { pathname: currentPathname },
    } = this.props;
    const { changeLayoutProps } = this.context;

    if (
      nextLocation.pathname !== currentPathname &&
      !!matchPath(nextLocation.pathname, {
        path,
        exact: true,
      })
    ) {
      const isModal = nextLocation.state && nextLocation.state.modal;
      const isActionPop = (nextLocation.state && nextLocation.state.action === 'POP') || history.action === 'POP';

      if (!disableScroll && !isModal && !isActionPop) {
        window.scrollTo(0, 0);
      }

      if (!disableTrackAffiliate) {
        const affiliateId = await this.getAffiliateId();

        if (affiliateId) {
          Route.trackAffiliate(uuid);
        } else if (referrerUserId && !logged) {
          Route.trackGuestAffiliate(referrerUserId);
        }
      }

      if (layoutProps !== nextLayoutProps) {
        changeLayoutProps(nextLayoutProps);
      }
    }
  }

  componentWillUnmount() {
    if (this.affTimeout) {
      clearTimeout(this.affTimeout);
    }
  }

  getAffiliateId = async () => {
    const { logged, uuid, client } = this.props;

    if (!logged) {
      return null;
    }

    const affiliateId = await client.query({
      query: GET_AFFILIATE_ID,
      variables: { playerUUID: uuid },
      skip: !uuid,
    });

    return get(affiliateId, 'data.player.data.affiliateId');
  };

  affTimeout = null;

  render() {
    const { disableScroll, disableTrackAffiliate, ...props } = this.props;

    return <DomRoute {...props} />;
  }
}

export default compose(
  withRouter,
  connect(({ auth: { uuid, logged }, affiliate: { referrerUserId } }) => ({
    logged,
    uuid,
    referrerUserId,
  })),
  withApollo
)(Route);
