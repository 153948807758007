import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Preloader from '../../../components/Preloader';
import isMobile from '../../../utils/isMobile';
import parseJson from '../../../utils/parseJson';
import { getApiUrl } from '../../../config';
import buildQueryString from '../../../utils/buildQueryString';
import getDeviceType from '../../../utils/getDeviceType';

class IgromatGame extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        internalGameId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    data: {
      gameSessionId: null,
      gameSessionURL: null,
      stopGameUrl: null,
    },
  };

  async componentDidMount() {
    const {
      match: {
        params: { internalGameId },
      },
    } = this.props;

    this.mounted = true;

    const gameInfoResponse = await this.loadGameInfo(internalGameId);

    if (gameInfoResponse && gameInfoResponse.startGameUrl) {
      const { gameSessionId, gameSessionURL } = await this.startGame(gameInfoResponse.startGameUrl);

      if (gameSessionURL) {
        if (isMobile.any()) {
          window.location = gameSessionURL;
        } else {
          this.updateState({ data: { ...this.state.data, gameSessionId, gameSessionURL }, loading: false });
        }
      } else {
        this.updateState({ error: { error: 't.game_session.not_found' }, loading: false });
      }
    } else {
      this.updateState({ error: { error: 't.entity.not.found' }, loading: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    if (!isMobile.any()) {
      this.stopGame();
    }
  }

  updateState = (...args) => {
    if (this.mounted) {
      this.setState(...args);
    }
  };

  loadGameInfo = (internalGameId) =>
    fetch(`${getApiUrl()}/game_info/public/games/${internalGameId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.props.token}`,
      },
    })
      .then((response) => response.text())
      .then((response) => parseJson(response));

  startGame = (startGameUrl) => {
    const { uuid: playerUUID, token, lang } = this.props;

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const query = {
      playerUUID,
      lang,
      type: getDeviceType(),
    };

    return fetch(`${getApiUrl()}${startGameUrl}&${buildQueryString(query)}`, options)
      .then((response) => response.text())
      .then((response) => parseJson(response));
  };

  stopGame = () => {
    const { uuid: playerUUID, token } = this.props;
    const {
      data: { stopGameUrl, gameSessionId },
    } = this.state;

    if (stopGameUrl && gameSessionId) {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      };

      const query = {
        playerUUID,
        gameSessionId,
      };

      return fetch(`${getApiUrl()}${stopGameUrl}?${buildQueryString(query)}`, options);
    }

    return false;
  };

  mounted = false;

  render() {
    const { error, loading: loadingSession } = this.state;
    const { t } = this.props;

    if (error) {
      return <div className="game-view">{t(error.error)}</div>;
    }

    if (loadingSession) {
      return (
        <div className="game-view">
          <Preloader className="game-view__loader" />
        </div>
      );
    }

    const {
      data: { gameSessionURL },
    } = this.state;

    return (
      <div className="embed-responsive embed-responsive-4by3 game-view">
        <Choose>
          <When condition={!loadingSession && !gameSessionURL}>{t('t.game_session.not_found')}</When>
          <Otherwise>
            <iframe
              width="100%"
              height="100%"
              className="embed-responsive-item"
              title="real game"
              src={gameSessionURL}
              scrolling="no"
              allowFullScreen
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

export default IgromatGame;
