import htmlParser from 'parse5';
import React from 'react';
import convertAttr from 'react-attr-converter';
import styleParser from './styleParser';
import Link from '../components/Link';

function renderElem(tagName, attr, children) {
  if (tagName === 'a') {
    return (
      <Link {...attr} to={attr.href}>
        {children}
      </Link>
    );
  }

  return React.createElement(tagName, attr, children);
}

function renderNode(node, key) {
  if (node.nodeName === '#text') {
    return node.value;
  }

  if (node.nodeName === '#comment') {
    return node.value;
  }

  const attr = node.attrs.reduce(
    (result, attributes) => {
      const name = convertAttr(attributes.name);

      result[name] = name === 'style' ? styleParser(attributes.value) : attributes.value;

      return result;
    },
    { key }
  );

  if (node.childNodes.length === 0) {
    return renderElem(node.tagName, attr);
  }

  if (node.nodeName === 'script') {
    attr.dangerouslySetInnerHTML = { __html: node.childNodes[0].value };

    return React.createElement('script', attr);
  }

  const children = node.childNodes.map(renderNode);

  return renderElem(node.tagName, attr, children);
}

export default function renderHTML(html) {
  const htmlAST = htmlParser.parseFragment(html);

  if (htmlAST.childNodes.length === 0) {
    return null;
  }

  const result = htmlAST.childNodes.map(renderNode);

  return result.length === 1 ? result[0] : result;
}
